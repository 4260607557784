import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getDatabase, ref, get, child } from 'firebase/database';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { Elements, useStripe, CardElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import config from '../config';
import nextImg from "../images/sipkabiladoprava.png";
import loadingImg from '../images/loadingImage.gif';
import loadingGif from '../images/7777.gif';
import xImg from "../images/Xkopopup.png";
import clickImg from "../images/clickwhite.png";

const firebaseConfig = {
  apiKey: config.firebase.apiKey,
  authDomain: config.firebase.authDomain,
  projectId: config.firebase.projectId,
  storageBucket: config.firebase.storageBucket,
  messagingSenderId: config.firebase.messagingSenderId,
  appId: config.firebase.appId,
  databaseURL: config.firebase.databaseURL,
  measurementId: config.firebase.measurementId,
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const database = getDatabase(app);
const firestore = getFirestore(app);
const stripePromise = loadStripe(config.stripePublicKey);

const FinishedWork = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentUserUid = auth.currentUser.uid;
  const { orderId } = location.state || {};
  const [rating, setRating] = useState('Ano');
  const [comment, setComment] = useState('');
  const [finishing, setFinishing] = useState(false);
  const [tip, setTip] = useState("0");
  const [price, setPrice] = useState("");
  const [stripeCustomerId, setStripeCustomerId] = useState('');
  const [savedCard, setSavedCard] = useState('');
  const [showCommentInput, setShowCommentInput] = useState(false);
  const stripe = useStripe();

  useEffect(() => {
    const savedCardFunction = async () => {
      if (!currentUserUid) return;
      const userDocRef = doc(firestore, 'users', currentUserUid);
      const userDoc = await getDoc(userDocRef);
      const stripeCustomerId = userDoc.data()?.stripeCustomerId;
      setStripeCustomerId(stripeCustomerId);
      if (!stripeCustomerId) return;
      const response = await axios.post(`${config?.firebaseFunctionEndpoint}/listPaymentMethods`, {
        customerId: stripeCustomerId,
      });
      const paymentMethods = response.data?.data;
      if (paymentMethods) {
        setSavedCard(paymentMethods[0]);
      }
    };
    savedCardFunction();
  }, [currentUserUid, orderId]);

  const initiatePaymentSheet = async (customerId, clientSecret) => {
    try {
      let ephemeralKey = null;
      if (stripeCustomerId) {
        const ephemeralKeyResponse = await axios.post(
          `${config?.firebaseFunctionEndpoint}/createEphemeralKey`,
          { customerId },
          { headers: { 'Content-Type': 'application/json' } }
        );
        ephemeralKey = ephemeralKeyResponse.data.id;
      }
      const { error } = await stripe.initPaymentSheet({
        customerId: customerId,
        customerEphemeralKeySecret: ephemeralKey.secret,
        paymentIntentClientSecret: clientSecret,
        allowsDelayedPaymentMethods: true,
        merchantDisplayName: 'Tasker',
        applePay: {
          merchantCountryCode: 'CZ',
          currencyCode: 'czk',
          requiredBillingContactFields: [],
        },
        googlePay: {
          merchantCountryCode: 'CZ',
          currencyCode: 'czk',
          testEnv: false,
        },
      });
      if (error) {
        alert('Error: ' + error.message);
        return;
      }
      const paymentSheetResult = await stripe.presentPaymentSheet({ clientSecret: clientSecret });
      if (!paymentSheetResult.error) {
        return paymentSheetResult;
      }
    } catch (error) {
      console.error('Failed to initialize payment sheet:', error);
      alert('Error: ' + error.message);
      return null;
    }
  };

  const handlePayment = async (useSavedCard = true) => {
    const orderRef = ref(database, `workInProgress/${currentUserUid}/${orderId}`);
    const orderDataSnapshot = await get(orderRef);
    const orderData = orderDataSnapshot.val();
    const price = orderData.price; // Fetch price from the order data
    const tipPercentage = parseInt(tip, 10);
    const totalPrice = (price * tipPercentage) / 100;

    if (!totalPrice) {
      alert('Cena není dostupná');
      return;
    }

    if (finishing) return;

    setFinishing(true);

    try {
      const paymentIntentResponse = await axios.post(
        `${config?.firebaseFunctionEndpoint}/createPaymentIntent`,
        {
          price: totalPrice,
          customerId: stripeCustomerId,
          savedCard: useSavedCard ? savedCard?.id : null,
          instantPayment: false,
          tipPayment: true
        },
        { headers: { 'Content-Type': 'application/json' } }
      );

      const { clientSecret, status, id } = paymentIntentResponse.data;

      if (!id) {
        alert("Chyba v platbě, zkuste to prosím znovu.");
        setFinishing(false);
        return;
      }

      if (status === 'requires_payment_method') {
        const paymentSheetInit = await initiatePaymentSheet(stripeCustomerId, clientSecret);
        if (!paymentSheetInit.error) {
          finishWork(totalPrice);
        }
      } else if (status === 'succeeded') {
        finishWork(totalPrice);
      }
    } catch (error) {
      console.error('Error during payment:', error);
      setFinishing(false);
    } finally {
    }
  };

  const finishWork = async (totalPrice) => {
    if (finishing) return;
    setFinishing(true);
    try {
      const response = await axios.post(`${config?.firebaseFunctionEndpoint}/finishWork`, {
        userId: currentUserUid,
        orderId: orderId,
        rating: rating === 'Ano' ? 100 : 0,
        comment: comment,
        tip: totalPrice ? totalPrice : "0"
      });

      if (response.data.success) {
        alert('Objednávka úspěšně dokončena, budeme se těšit na Vaši další objednávku!');
        navigate('/');
      } else {
        alert('Chyba, došlo k chybě při odesílání zpětné vazby. Objednávka byla pravděpodobně dokončena po 30 minutách nečinnosti');
      }
    } catch (error) {
      console.error('Error submitting feedback:', error);
      alert('Chyba, došlo k chybě při odesílání zpětné vazby. Objednávka byla pravděpodobně dokončena po 30 minutách nečinnosti');
    } finally {
      setFinishing(false);
    }
  };

  const handleTipSelection = (selectedTip) => {
    setTip(selectedTip);
  };

  const handleSubmit = async () => {
    if (tip === "0") {
      finishWork();
    } else {
      await handlePayment();
    }
  };

  return (
    <Elements stripe={stripePromise}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: 'white', justifyContent:"center"}}>
        <div style={{ width: '100%', maxWidth: '600px', backgroundColor: 'white', alignItems: 'center', textAlign: 'center', justifyContent:"center"}}>
          <h1 style={{ fontWeight: 'bold', color: 'black', fontSize: '24px', marginTop: '50px' }}>Byli jste spokojeni se službou?</h1>
          <div style={{ display: 'flex', flexDirection: 'row', marginTop: '20px', justifyContent: 'center' }}>
            <button
              style={{ 
                width: '55px', 
                height: '55px', 
                borderRadius: '30px', 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center', 
                border: "none",
                borderWidth: '2px', 
                borderColor: rating === 'Ano' ? '#00da00' : 'lightgray', 
                backgroundColor: rating === 'Ano' ? '#00da00' : '#F2F5F8', 
                margin: '0 10px', 
                cursor: 'pointer' 
              }}
              onClick={() => setRating('Ano')}
            >
              <span style={{ fontSize: '16px', color: rating === 'Ano' ? 'white' : 'gray' }}>Ano</span>
            </button>
            <button
              style={{ 
                width: '55px', 
                height: '55px', 
                borderRadius: '30px', 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center',
                border: "none", 
                borderWidth: '2px', 
                borderColor: rating === 'Ne' ? '#00da00' : 'lightgray', 
                backgroundColor: rating === 'Ne' ? '#00da00' : '#F2F5F8', 
                margin: '0 10px', 
                cursor: 'pointer' 
              }}
              onClick={() => setRating('Ne')}
            >
              <span style={{ fontSize: '16px', color: rating === 'Ne' ? 'white' : 'gray' }}>Ne</span>
            </button>
          </div>

          <div style={{position:"relative", flexDirection: 'column', backgroundColor: '#F2F5F8', padding: '10px 20px', width:"90%", left:"50%", transform:"translate(-50%)", borderRadius: '8px', marginTop: '20px',  }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
              <span style={{ fontSize: '16px', color: 'black', fontWeight: 'bold' }}>DÝŠKO</span>
              <img src={require("../images/clickblack.png")} style={{ width: '30px', height: '30px' }} alt="Click" />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '0', marginTop: '10px' }}>
              <button
                style={{ 
                  backgroundColor: tip === '0' ? '#8dff8d' : 'white', 
                  padding: '10px 0', 
                  alignItems: 'center', 
                  flex: '1', 
                  borderWidth: tip === '0' ? '1px' : '0', 
                  borderColor: '#2ab043', 
                  borderRadius: '8px', 
                  cursor: 'pointer' 
                }}
                onClick={() => handleTipSelection('0')}
              >
                <span>0%</span>
              </button>
              <button
                style={{ 
                  backgroundColor: tip === '10' ? '#8dff8d' : 'white', 
                  padding: '10px 0', 
                  alignItems: 'center', 
                  flex: '1', 
                  borderWidth: tip === '10' ? '1px' : '0', 
                  borderColor: '#2ab043', 
                  borderRadius: '8px', 
                  cursor: 'pointer' 
                }}
                onClick={() => handleTipSelection('10')}
              >
                <span>10%</span>
              </button>
              <button
                style={{ 
                  backgroundColor: tip === '20' ? '#8dff8d' : 'white', 
                  padding: '10px 0', 
                  alignItems: 'center', 
                  flex: '1', 
                  borderWidth: tip === '20' ? '1px' : '0', 
                  borderColor: '#2ab043', 
                  borderRadius: '8px', 
                  cursor: 'pointer' 
                }}
                onClick={() => handleTipSelection('20')}
              >
                <span>20%</span>
              </button>
            </div>
          </div>

          <span style={{position:"relative", display:"inline-block", color: 'gray', marginTop: 20 }}>Vaše zpětná vazba je anonymní</span>
          <textarea
            style={{ width: '90%', backgroundColor: '#f2f5f8', borderRadius: '5px', padding: '10px', marginTop: '10px', height: '80px' }}
            placeholder="Napište recenzi zde..."
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            maxLength={5000}
          />
        </div>
        <button 
          style={{ 
            alignSelf: 'center', 
            alignItems: 'center', 
            padding: '20px', 
            marginBottom: '20px', 
            marginTop: '10px', 
            width: '90%',
            maxWidth:300, 
            backgroundColor: '#00da00', 
            borderRadius: '10px', 
            display: 'flex', 
            flexDirection: 'row',
            justifyContent:"space-between", 
            border: 'none', 
            cursor: 'pointer'
          }} 
          onClick={handleSubmit}
        >
          <span style={{ color: 'white', fontSize: '20px', fontWeight: 'bold', marginLeft: '15px' }}>{"Dokončit"}</span>
          {/* <img src={clickImg} style={{ width: '35px', height: '35px', marginLeft: '15px' }} alt="Click" /> */}
          <img src={finishing ? loadingGif : nextImg} style={{right: '20px', width: '20px', height: '20px' }} alt="Loading" />
        </button>

        <button 
          style={{ 
            textAlign: 'center', 
            alignSelf: 'center', 
            marginBottom: '50px', 
            background: 'none', 
            border: 'none', 
            cursor: 'pointer' 
          }} 
          onClick={() => setShowCommentInput(true)}
        >
          <span style={{ color: 'gray' }}>Chcete napsat recenzi? <span style={{ color: '#00da00', textDecoration: 'underline' }}>Zanechte komentář</span></span>
        </button>
      </div>
    </Elements>
  );
};

export default FinishedWork;
