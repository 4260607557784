import React, { useEffect, useState, useRef } from 'react';
import appStoreImage from '../images/appstore.png';
import googlePlayImage from '../images/googlePlay-cs.png';
import qrCodeImage from '../images/QRtasker.gif';

const Modal = ({ isOpen, onClose }) => {
  const closeButtonRef = useRef(null);
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 750);
  const [isMediumScreen, setIsMediumScreen] = useState(window.innerWidth > 500);

  const updateMedia = () => {
    setIsWideScreen(window.innerWidth > 750);
    setIsMediumScreen(window.innerWidth > 500);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  }, []);

  useEffect(() => {
    if (isOpen) {
      closeButtonRef.current.focus();
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div style={{ 
      position: 'fixed', 
      top: 0, 
      left: 0, 
      right: 0, 
      bottom: 0, 
      background: 'rgba(0, 0, 0, 0.5)', 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center',
      zIndex:1000
    }}>
      <div style={{ 
        background: 'white', 
        borderRadius: '10px', 
        textAlign: 'center',
        padding:20,
        width:"calc(90% - 40px)",
        maxWidth: 600,
        position: 'relative'
      }}>
        <h2>Tato funkce je dostupná pouze v naší aplikaci</h2>
        <p>Najdete ji v App Store nebo Google Play:</p>
        
        <div style={{ 
          display: 'flex', 
          flexDirection: isMediumScreen ? 'row' : 'column',
          justifyContent: isMediumScreen ? 'space-around' : 'center',
          alignItems: 'center',
          marginTop: '20px',
          gap: '20px'
        }}>
          <div style={{ 
            display: 'flex', 
            flexDirection: 'column', 
            backgroundColor: 'white', 
            padding: isMediumScreen ? '15px' : '20px', 
            borderRadius: '15px', 
            alignItems: 'center',
            width: '100px'
          }}>
            <a href="https://onelink.to/ktj2se" target="_blank" rel="noopener noreferrer">
              <img src={appStoreImage} alt="Download" style={{ width: isMediumScreen ? '120px' : '100px' }} loading="lazy" />
            </a>
            <a href="https://onelink.to/ktj2se" target="_blank" rel="noopener noreferrer">
              <img src={qrCodeImage} alt="Download" style={{ width: '80px', marginTop: '20px' }} loading="lazy" />
            </a>
          </div>
          <div style={{ 
            display: 'flex', 
            flexDirection: 'column', 
            backgroundColor: 'white', 
            padding: isMediumScreen ? '15px' : '20px', 
            borderRadius: '15px', 
            alignItems: 'center',
            width: '100px'
          }}>
            <a href="https://onelink.to/ktj2se" target="_blank" rel="noopener noreferrer">
              <img src={googlePlayImage} alt="Download" style={{ width: isMediumScreen ? '120px' : '100px' }} loading="lazy" />
            </a>
            <a href="https://onelink.to/ktj2se" target="_blank" rel="noopener noreferrer">
              <img src={qrCodeImage} alt="Download" style={{ width: '80px', marginTop: '20px' }} loading="lazy" />
            </a>
          </div>
        </div>
        
        <button 
          ref={closeButtonRef} 
          onClick={onClose} 
          style={{ 
            background: '#00da00', 
            color: 'white', 
            padding: '10px 20px', 
            border: 'none', 
            borderRadius: '5px', 
            cursor: 'pointer', 
            fontWeight: 'bold', 
            marginTop: '20px' 
          }}
        >
          Zavřít
        </button>
      </div>
    </div>
  );
};

export default Modal;
