import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { getDatabase, ref, onValue, off, get } from 'firebase/database';
import { getFirestore } from 'firebase/firestore';
import axios from 'axios';
import { GoogleMap, Marker, DirectionsRenderer, useJsApiLoader } from '@react-google-maps/api';
import loadingImg from '../images/loadingImage.gif';
import employeePhoto from '../images/workercekani.png';
import xImg from "../images/Xkopopup.png";
import homeIcon from "../images/homeicon.png";
import Profilegrey from '../images/Profilegrey.png';
import nextImg from "../images/xsedej.png";
import config from '../config';
import Modal from '../components/appModal';

// Define libraries outside of the component
const libraries = ['places'];

const EmployeeRoute = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const auth = getAuth();
  const database = getDatabase();
  const firestore = getFirestore();

  const currentUserUid = auth.currentUser?.uid;
  const { orderId } = location.state;
  const [time, setTime] = useState("");
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [driveRate, setDriveRate] = useState("");
  const [locationData, setLocationData] = useState(null);
  const [assignedEmployees, setAssignedEmployees] = useState({});
  const [routeResults, setRouteResults] = useState({});
  const [longestRouteInfo, setLongestRouteInfo] = useState(null);
  const [countdown, setCountdown] = useState(null);
  const [inputAddress, setInputAddress] = useState('');
  const [routeStartTime, setRouteStartTime] = useState(null);
  const [showRefundModal, setShowRefundModal] = useState(false);
  const [pressed, setPressed] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const [directions, setDirections] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: config.GOOGLE_PLACES_API_KEY,
    libraries, // Use the static array defined outside the component
  });

  useEffect(() => {
    const fetchOrderData = async () => {
      const orderDataRef = ref(database, `workInProgress/${currentUserUid}/${orderId}`);
      const orderDataSnapshot = await get(orderDataRef);
      const orderData = orderDataSnapshot.val();
      if (orderData) {
        setLocationData(orderData.location);
        setAssignedEmployees(orderData.assignedEmployees || {});
        setInputAddress(orderData.inputAddress);
        setRouteStartTime(orderData.routeStartTime);
        setDriveRate(orderData.driveRate);
      }
    };
    fetchOrderData();

    return () => {};
  }, [orderId]);

  useEffect(() => {
    if (!routeStartTime && !assignedEmployees) return;

    let timerId;

    const calculateCountdown = () => {
      const longestEta = Math.max(...Object.values(assignedEmployees).map(emp => emp.eta || 0));
      const startTime = new Date(routeStartTime);
      const currentTime = new Date();
      const timeDiff = (currentTime - startTime) / 1000;
      const remainingTime = (longestEta + 10) * 60 - timeDiff;
      if (remainingTime <= 0) {
        setCountdown('Dorazí brzy');
        clearInterval(timerId);
      } else {
        setCountdown(remainingTime);
      }
    };

    calculateCountdown();
    timerId = setInterval(calculateCountdown, 60000);
    return () => clearInterval(timerId);
  }, [routeStartTime, assignedEmployees, orderId]);

  useEffect(() => {
    if (!Object.keys(assignedEmployees).length || !orderId) return;
    const listeners = [];
    Object.keys(assignedEmployees).forEach(employeeId => {
      const employeeLocationRef = ref(database, `workInProgress/${currentUserUid}/${orderId}/assignedEmployees/${employeeId}/location`);
      const handleLocationChange = (snapshot) => {
        const updatedLocation = snapshot.val();
        if (updatedLocation) {
          setAssignedEmployees(prevState => ({
            ...prevState,
            [employeeId]: {
              ...prevState[employeeId],
              location: updatedLocation
            }
          }));
        }
      };
      onValue(employeeLocationRef, handleLocationChange);
      listeners.push({ ref: employeeLocationRef, handler: handleLocationChange });
    });
    return () => {
      listeners.forEach(({ ref, handler }) => {
        off(ref, 'value', handler);
      });
    };
  }, [Object.keys(assignedEmployees).length, orderId]);

  useEffect(() => {
    const chatRef = ref(database, `workInProgress/${currentUserUid}/${orderId}/chat`);

    const onChatUpdate = (snapshot) => {
      const chatData = snapshot.val();
      if (chatData) {
        const unreadMessages = Object.values(chatData).filter(
          (message) => message.senderId !== currentUserUid && !message.isRead
        );
        setUnreadCount(unreadMessages.length);
      }
    };

    onValue(chatRef, onChatUpdate);

    return () => {
      off(chatRef, 'value', onChatUpdate);
    };
  }, [currentUserUid, orderId]);

  useEffect(() => {
    const clientRef = ref(database, `workInProgress/${currentUserUid}/${orderId}/page`);
    const handlePageSnapshot = (snapshot) => {
      const data = snapshot.val();
      if (data && data !== "EmployeeRoute") {
        navigate(`/${data}`, { state: { orderId: orderId } });
      }
    };

    onValue(clientRef, handlePageSnapshot);

    return () => {
      off(clientRef, 'value', handlePageSnapshot);
    };
  }, [orderId, currentUserUid, navigate]);

  useEffect(() => {
    if (locationData && isLoaded && Object.keys(assignedEmployees).length > 0) {
      const directionsService = new window.google.maps.DirectionsService();
      const allDirections = Object.entries(assignedEmployees).map(([employeeId, employee]) => {
        return new Promise((resolve, reject) => {
          directionsService.route(
            {
              origin: new window.google.maps.LatLng(employee.location.lat, employee.location.lon),
              destination: new window.google.maps.LatLng(locationData.lat, locationData.lon),
              travelMode: window.google.maps.TravelMode.DRIVING,
            },
            (result, status) => {
              if (status === window.google.maps.DirectionsStatus.OK) {
                resolve({ employeeId: employeeId, directions: result });
              } else {
                reject(status);
              }
            }
          );
        });
      });

      Promise.all(allDirections)
        .then(results => {
          setDirections(results);

          // Calculate the longest route
          let longest = null;
          results.forEach(({ employeeId, directions }) => {
            const route = directions.routes[0];
            const duration = route.legs[0].duration.value; // Duration in seconds
            const distance = route.legs[0].distance.value; // Distance in meters

            if (!longest || duration > longest.duration) {
              longest = { distance, duration, employeeId };
            }
          });

          setLongestRouteInfo({
            distance: (longest.distance / 1000).toFixed(2), // Convert to km and format
            duration: (longest.duration / 60).toFixed(0),   // Convert to minutes and format
            employeeId: longest.employeeId
          });
        })
        .catch(error => {
          console.error("Error fetching directions:", error);
        });
    }
  }, [locationData, assignedEmployees, isLoaded]);

  const handleChatPress = () => {
    if (!orderId) return;
    navigate('/chat', { state: { orderId } });
  };

  const handleRefund = async (captureDriveRate = true, isFutureOrder = false) => {
    try {
      const response = await axios.post(`${config?.firebaseFunctionEndpoint}/createRefund`, {
        orderId: orderId,
        userId: currentUserUid,
        captureDriveRate,
        isFutureOrder,
      });

      if (response.data.refunded) {
        alert('Platba byla vrácena');
        navigate('/');
      } else {
        alert('Refund request failed');
      }
    } catch (error) {
      alert('Error requesting refund.');
    }
  };

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!locationData || !isLoaded) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <img src={loadingImg} alt="loading" style={{ width: 100, height: 100 }} />
      </div>
      );
  }

  return (
    <div style={{ flex: 1, alignItems: "center", backgroundColor: "white", position: 'relative', padding: '20px' }}>
      <Modal isOpen={isModalOpen} onClose={()=>{setIsModalOpen(false)}} />


      {locationData && Object.keys(assignedEmployees).length > 0 ? (
        <GoogleMap
          mapContainerStyle={{ width: '100%', height: '300px' }}
          center={{ lat: locationData.lat, lng: locationData.lon }}
          zoom={12}
        >
          <Marker position={{ lat: locationData.lat, lng: locationData.lon }} icon={homeIcon} />

          {Object.entries(assignedEmployees).map(([uid, employee]) => (
            <React.Fragment key={uid}>
              <Marker position={{ lat: employee.location.lat, lng: employee.location.lon }} icon={Profilegrey} />
              {directions.find(direction => direction.employeeId === uid)?.directions && (
                <DirectionsRenderer
                  directions={directions.find(direction => direction.employeeId === uid).directions}
                />
              )}
            </React.Fragment>
          ))}
        </GoogleMap>
      ) : (
        <img src={loadingImg} alt="Loading" style={{ width: '35px', height: '35px' }} />
      )}

      <div style={{ maxWidth: "100%", gap: '5px', alignItems: "center", justifyContent: "center", textAlign:"center", backgroundColor: "#F2F5F8", borderRadius: '10px', marginTop: '20px', padding: '15px' }}>
        <p style={{ textAlign: "center", color: "gray" }}>Na cestě k: <strong>{inputAddress}</strong></p>
        <p style={{ color: "black", fontSize: '26px', fontWeight: "bold" }}>
          {countdown !== 'Dorazí brzy' && countdown !== null ? `${Math.ceil(countdown / 60)} Min (Odhad)` : 'Dorazí brzy (Odhad)'}
        </p>
        <p style={{ color: "gray" }}>{longestRouteInfo ? `${longestRouteInfo.distance} Km Daleko (Odhad)` : 'N/A'}</p>
        <p style={{ color: "gray", fontSize: '22px', }}>
          Pro přesnější informaci ohledně dojezdu kontaktujte pracovníka přes chat.
        </p>
      </div>

      <button
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          marginTop: '15px',
          padding: '15px',
          backgroundColor: "#F2F5F8",
          borderRadius: '15px',
          cursor: 'pointer',
          border: "none"
        }}
        onClick={()=>{setIsModalOpen(true)}}
      >
        <img style={{ width: '20px', height: '20px' }} src={require("../images/Chatgrey.png")} alt="Chat" />
        <p style={{ color: "gray", fontSize: '13px', textAlign: "center", marginLeft: '10px', fontWeight: "bold", textDecoration: "underline" }}>
          {`Otevřít chat s dodavatelem (${unreadCount})`}
        </p>
      </button>

    <div style={{width:"100%", display:"flex", justifyContent:"center", alignItems:"center", marginTop:20}}>
      <button 
        style={{ 
          position: "relative", 
          alignSelf: "center", 
          alignItems: "center", 
          background: 'none', 
          border: 'none', 
          cursor: 'pointer' 
        }} 
        onClick={() => setShowRefundModal(true)}
      >
        {pressed ? (
          <img src={loadingImg} alt="Loading" style={{ width: '35px', height: '35px' }} />
        ) : (
          <img src={xImg} alt="Cancel" style={{ width: '35px', height: '35px' }} />
        )}
        <p style={{ color: "black", fontSize: '15px' }}>{pressed ? "Načítání..." : "Zrušit Objednávku"}</p>
      </button>
    </div>
      {showRefundModal && (
        <div style={{ 
          position: 'fixed', 
          top: 0, 
          left: 0, 
          right: 0, 
          bottom: 0, 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          backgroundColor: 'rgba(0, 0, 0, 0.5)' 
        }}>
          <div style={{ 
            justifyContent: "center", 
            alignItems: "center", 
            backgroundColor: 'white', 
            padding: '20px', 
            borderRadius: '10px', 
            width: "80%", 
            gap: '20px' 
          }}>
            <p style={{ textAlign: "center", fontSize: '18px', fontWeight: 'bold', marginBottom: '10px', color: "gray" }}>
              Opravdu chcete zrušit objednávku? Zaměstnanec už je na cestě. Může být účtována cesta za dopravu pracovníka v hodnotě {`${driveRate}`} Kč
            </p>
            <div style={{ display: 'flex', flexDirection: "row", justifyContent:"center", gap: '20px' }}>
              <button 
                style={{ padding: '10px', backgroundColor: 'green', borderRadius: '5px', color: 'white', cursor: 'pointer', border:"none" }} 
                onClick={() => handleRefund(true)}
              >
                Ano
              </button>
              <button 
                style={{ padding: '10px', backgroundColor: 'red', borderRadius: '5px', color: 'white', cursor: 'pointer',border:"none"  }} 
                onClick={() => setShowRefundModal(false)}
              >
                Ne
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EmployeeRoute;
