import React, { useState, useEffect, useRef, useCallback } from 'react';
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithPhoneNumber, RecaptchaVerifier, PhoneAuthProvider, signInWithCredential } from 'firebase/auth';
import { getFirestore, doc, getDoc, setDoc } from 'firebase/firestore';
import { getDatabase } from 'firebase/database';
import { getAnalytics } from 'firebase/analytics';
import config from '../config';
import countryData from '../countries.json';
import CountryFlag from 'react-country-flag';
import { Link, useNavigate } from 'react-router-dom';
import loadingImg from '../images/loadingImage.gif';
import clickImg from '../images/clickwhite.png';
import loadingGif from '../images/7777.gif';

// Initialize Firebase
const firebaseConfig = {
  apiKey: config.firebase.apiKey,
  authDomain: config.firebase.authDomain,
  projectId: config.firebase.projectId,
  storageBucket: config.firebase.storageBucket,
  messagingSenderId: config.firebase.messagingSenderId,
  appId: config.firebase.appId,
  databaseURL: config.firebase.databaseURL,
  measurementId: config.firebase.measurementId,
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const analytics = getAnalytics(app);
const firestore = getFirestore(app);
const database = getDatabase(app);

function Register() {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [pressed, setPressed] = useState(false);
  const [areas, setAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const navigate = useNavigate();

  // New state variables for OTP
  const [showOTP, setShowOTP] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [resendAttempts, setResendAttempts] = useState(0);
  const [resendCode, setResendCode] = useState(false);
  const [timer, setTimer] = useState(60);
  const [isCodeWrong, setIsCodeWrong] = useState(false);
  const [confirmResult, setConfirmResult] = useState(null);
  const recaptchaVerifierRef = useRef(null);
  const recaptchaContainerRef = useRef(null);
  const [is500, setIs500] = useState(window.innerWidth < 500);
  const [is750, setIs750] = useState(window.innerWidth < 750);
  const [is1000, setIs1000] = useState(window.innerWidth < 1000);

  const updateMedia = () => {
    setIs1000(window.innerWidth < 1000);
    setIs750(window.innerWidth < 750);
    setIs500(window.innerWidth < 500);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  }, []);

  const initializeRecaptcha = useCallback(() => {
    if (!recaptchaVerifierRef.current && recaptchaContainerRef.current) {
      recaptchaVerifierRef.current = new RecaptchaVerifier(auth, 'recaptcha-container', {
        size: 'invisible',
        callback: () => {
            console.log('recaptcha resolved..')
        }
    });
      recaptchaVerifierRef.current.render();
    }
  }, [auth]);

  useEffect(() => {
    const areaData = countryData.map(item => ({
      code: item.alpha2Code,
      name: item.name,
      callingCode: `+${item.callingCodes[0]}`,
    }));
    setAreas(areaData);
    const defaultData = areaData.find(a => a.code === "CZ") || areaData[0];
    setSelectedArea(defaultData);

    initializeRecaptcha();

    return () => {
      if (recaptchaVerifierRef.current) {
        recaptchaVerifierRef.current.clear();
        recaptchaVerifierRef.current = null;
      }
    };
  }, [initializeRecaptcha]);

  const handlePhoneNumberChange = (data) => {
    const numericData = data.replace(/[^0-9]/g, ''); // Remove any non-numeric characters
    setPhoneNumber(numericData);
  };

  const onRegisterPhone = async () => {
    setPressed(true);
    if (!phoneNumber.trim()) {
      setPressed(false);
      window.alert("Zadejte telefonní číslo");
      return;
    }

    const phoneNumberWithCode = selectedArea?.callingCode + phoneNumber;

    try {
      if (!recaptchaVerifierRef.current) {
        initializeRecaptcha();
        if (!recaptchaVerifierRef.current) {
          throw new Error('reCAPTCHA verifier not initialized');
        }
      }

      const db = firestore;
      const collectionName = 'phoneNumbers';
      const documentId = 'phoneNumbers';
      const fieldName = 'phoneNumbers';

      const docRef = doc(db, collectionName, documentId);
      const docSnapshot = await getDoc(docRef);

      if (docSnapshot.exists()) {
        const phoneNumbersArray = docSnapshot.data()?.[fieldName] || [];
        if (!phoneNumbersArray.includes(phoneNumberWithCode)) {
          await setDoc(docRef, { [fieldName]: [...phoneNumbersArray, phoneNumberWithCode] });
        }
      } else {
        await setDoc(docRef, { [fieldName]: [phoneNumberWithCode] });
      }

      const response = await fetch(`${config.firebaseFunctionEndpoint}/checkPhoneNumberRegistration`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          phoneNumber: phoneNumberWithCode,
        }),
      });
      const { isRegistered, hasEmail } = await response.json();

      if (isRegistered && hasEmail) {
        setPressed(false);
        window.alert('Error, Toto číslo je registrováno');
      } else {
        const result = await signInWithPhoneNumber(auth, phoneNumberWithCode, recaptchaVerifierRef.current);
        setConfirmResult(result);
        setShowOTP(true);
      }
    } catch (error) {
      console.error('Detailed error:', error);
      window.alert('Error, Nastala chyba při ověřování čísla: ' + error.message);
    } finally {
      setPressed(false);
    }
  };

  const onVerifyCode = async () => {
    if (!verificationCode.trim()) {
      window.alert("Zadejte kód z sms");
      return;
    }
    try {
      const credential = PhoneAuthProvider.credential(confirmResult.verificationId, verificationCode);
      await signInWithCredential(auth, credential);
      setIsCodeWrong(false);
      navigate("/register3");
    } catch (error) {
      setIsCodeWrong(true);
      setVerificationCode('');
      console.error(error);
    }
  };

  const handleResendCode = async () => {
    if (resendAttempts < 3) {
      setResendCode(true);
      try {
        const result = await signInWithPhoneNumber(auth, selectedArea?.callingCode + phoneNumber, recaptchaVerifierRef.current)
        setConfirmResult(result);
        setResendAttempts(resendAttempts + 1);
      } catch (error) {
        window.alert("Error: " + error.message);
      }
    } else {
      setResendCode(false);
      window.alert("Přesáhli jste maximální počet odeslání kódu");
    }
  };

  useEffect(() => {
    let interval;
    if (timer > 0 && resendCode) {
      interval = setInterval(() => {
        setTimer((timer) => timer - 1);
      }, 1000);
    } else {
      setResendCode(false);
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [timer, resendCode]);

  const buttonStyle = {
    backgroundColor: '#00da00',
    color: 'white',
    border: 'none',
    alignSelf:"center",
    borderRadius: '8px',
    padding: '15px 30px',
    fontSize: is500 ? "15px" : '18px',
    fontWeight: 'bold',
    cursor: 'pointer',
    width: is500 ? "100%" : "60%",
    transition: 'background-color 0.3s',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    display: "flex",
    flexDirection:"row", 
    alignItems:"center"
  };

  const inputStyle = {
    width: '100%',
    height: '50px',
    paddingLeft: '15px',
    border: 'none',
    outline: 'none',
    backgroundColor: '#f2f5f8',
    borderRadius: '8px',
    fontSize: '16px',
  };

  if (!selectedArea) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: '#f0f2f5' }}>
        <img src={loadingImg} alt="loading" style={{ width: 100, height: 100 }} />
      </div>
    );
  }

  return (
    <div style={{ padding: '20px', maxWidth: '100%', margin: '0 auto', marginTop:"20px", alignItems:"center", alignSelf:"center", justifyContent:"center", }}>
      <div style={{ marginBottom: '40px', textAlign: 'center' }}>
        <h1 style={{ fontWeight: 'bold', color: '#333', marginBottom: '10px' }}>Pojďme začít!</h1>
        <p style={{ color: '#666', fontSize: '18px' }}>
          {showOTP ? "Zadejte kód z SMS" : (
            <>
              Od profesionálního úklidu už Vás dělí jen rychlá registrace.
              <br />
              <br />
              Zadejte své telefonní číslo. Pošleme vám tam potvrzovací kód.
            </>
          )}
        </p>
      </div>

      {!showOTP ? (
        <>
          <div style={{ marginBottom: '30px', display:"flex", flexDirection:"row", justifyContent:"center", width:"100%" }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px', maxWidth:"600px", width:"90%" }}>
              <div 
                style={{ 
                  width: '30%', 
                  backgroundColor: '#f2f5f8', 
                  borderRadius: '8px', 
                  display: 'flex', 
                  alignItems: 'center', 
                  justifyContent: 'center', 
                  cursor: 'pointer',
                  height: '50px',
                }} 
                onClick={() => setModalVisible(true)}
              >
                {selectedArea && (
                  <>
                       <CountryFlag countryCode={selectedArea.code.toLowerCase()} svg style={{ width: is500 ? 15 : 25, height: 15 }} />
                       <span style={{ color: 'black', marginLeft: 5, fontSize: is500 ? 12 : 16 }}>{selectedArea.callingCode}</span>
                  </>
                )}
              </div>
              <div style={{ width: '70%' }}>
                <input
                  type="text"
                  style={inputStyle}
                  onChange={(e) => handlePhoneNumberChange(e.target.value)}
                  placeholder="Telefonní číslo"
                />
              </div>
            </div>
          </div>

          <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
            <span style={{ fontSize: '16px', color: '#666' }}>Již máte účet?</span>
            <Link to="/login" style={{ fontSize: '16px', color: '#2ab043', marginLeft: '5px', textDecoration: 'none' }}>Přihlásit se</Link>
          </div>
        <div style={{position:"relative", alignSelf:"center", margin: "0 auto", display:"flex",flexDirection:"row", width:"100%", maxWidth:"500px", justifyContent:"center", alignItems:"center"}}>
          <button
              style={buttonStyle}
              onClick={onRegisterPhone}
            >
              {pressed ? "Načítání..." : "ZAREGISTROVAT SE"}
              <img src={pressed ? loadingGif : clickImg} alt="loading icon" style={{ width: 25, height: 25, marginLeft: 15 }} />
            </button>
        </div>
        </>
      ) : (
        <>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {isCodeWrong && <p style={{ color: 'red', marginBottom: '10px' }}>Neplatný kód</p>}
            <div style={{ display: 'flex', justifyContent: 'center', gap: is500 ? 5 : '10px', marginBottom: '20px' }}>
              {[...Array(6)].map((_, index) => (
                <input
                  key={index}
                  type="text"
                  maxLength="1"
                  style={{
                    width: is500 ? 25 : '40px',
                    height: is500 ? 30 : '50px',
                    fontSize: '24px',
                    textAlign: 'center',
                    border: `2px solid ${isCodeWrong ? 'red' : '#00000030'}`,
                    borderRadius: '8px',
                    backgroundColor: '#f2f5f8',
                  }}
                  value={verificationCode[index] || ''}
                  onChange={(e) => {
                    const newCode = verificationCode.split('');
                    newCode[index] = e.target.value;
                    setVerificationCode(newCode.join(''));
                    if (e.target.value && e.target.nextSibling) {
                      e.target.nextSibling.focus();
                    }
                  }}
                />
              ))}
            </div>
            <button
              style={buttonStyle}
              onClick={onVerifyCode}
            >
              Ověřit kód
            </button>
            <button
              style={{
                marginTop: '15px',
                backgroundColor: 'transparent',
                color: '#2ab043',
                border: 'none',
                cursor: 'pointer',
                fontSize: '16px',
              }}
              onClick={handleResendCode}
              disabled={resendCode}
            >
              {resendCode ? `Poslat znovu za ${timer}...` : 'Neobdržel(a) jsem kód'}
            </button>
          </div>
        </>
      )}

      {modalVisible && (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0,0,0,0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 1000,
        }}>
          <div style={{
            backgroundColor: '#fff',
            borderRadius: '15px',
            padding: '30px',
            maxHeight: '80%',
            overflowY: 'auto',
            position: 'relative',
            width: '90%',
            maxWidth: '400px',
          }}>
            <button
              onClick={() => setModalVisible(false)}
              style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                backgroundColor: 'transparent',
                border: 'none',
                fontSize: '24px',
                cursor: 'pointer',
                color: '#666',
              }}
            >
              &times;
            </button>
            {areas.map((area) => (
              <div 
                key={area.code} 
                style={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  padding: '15px', 
                  cursor: 'pointer',
                  borderBottom: '1px solid #f0f0f0',
                  transition: 'background-color 0.2s',
                }}
                onClick={() => { setSelectedArea(area); setModalVisible(false); }}
              >
                <CountryFlag countryCode={area.code.toLowerCase()} svg style={{ width: 25, height: 20 }} />
                <span style={{ marginLeft: '15px', fontSize: '16px' }}>{area.name}</span>
                <span style={{ marginLeft: 'auto', color: '#666' }}>{area.callingCode}</span>
              </div>
            ))}
          </div>
        </div>
      )}
      <div ref={recaptchaContainerRef} id="recaptcha-container"></div>
    </div>
  );
}

export default Register;
