import React, { useState, useEffect, useContext, useRef } from 'react';
import { initializeApp } from 'firebase/app';
import { getAuth, EmailAuthProvider, linkWithCredential, updateProfile } from 'firebase/auth';
import { getFirestore, doc, setDoc, getDoc } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getDatabase } from 'firebase/database';
import { getAnalytics } from 'firebase/analytics';
import config from '../config';
import { Link, useNavigate } from 'react-router-dom';
import loadingGif from '../images/7777.gif';
import nextImg from '../images/sipkabiladoprava.png';
import profilImg from '../images/userblack.png';
import emailImg from '../images/mailblack.png';
import passwordImg from '../images/padlock.png';
import clickBlackImg from '../images/clickblack.png';
import sizeImg from '../images/rozmeryblack.png';
import locationImg from '../images/locationblack.png';
import clickImg from '../images/clickwhite.png';
import { UserContext } from '../UserContext';

// Initialize Firebase
const firebaseConfig = {
  apiKey: config.firebase.apiKey,
  authDomain: config.firebase.authDomain,
  projectId: config.firebase.projectId,
  storageBucket: config.firebase.storageBucket,
  messagingSenderId: config.firebase.messagingSenderId,
  appId: config.firebase.appId,
  databaseURL: config.firebase.databaseURL,
  measurementId: config.firebase.measurementId,
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const analytics = getAnalytics(app);
const firestore = getFirestore(app);
const storage = getStorage(app);
const database = getDatabase(app);

function Register3() {
  const { setRegistrationComplete } = useContext(UserContext);
  const [pressed, setPressed] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [inputAddress, setInputAddress] = useState('');
  const [state, setState] = useState('');
  const [country, setCountry] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [photoURL, setPhotoURL] = useState(
    'https://firebasestorage.googleapis.com/v0/b/test-26394.appspot.com/o/user-icon.webp?alt=media&token=6271e7d2-e0b6-4695-b466-9a8274d26b8b'
  );

  const [nameValid, setNameValid] = useState(true);
  const [emailValid, setEmailValid] = useState(true);
  const [passwordValid, setPasswordValid] = useState(true);
  const [confirmPasswordValid, setConfirmPasswordValid] = useState(true);
  const [requiresRecentLogin, setRequiresRecentLogin] = useState(false);

  const [showCompanyInputs, setShowCompanyInputs] = useState(false);
  const [ico, setIco] = useState('');
  const [dic, setDic] = useState('');
  const [companyName, setCompanyName] = useState('');

  const [selectedAddress, setSelectedAddress] = useState("");
  const [apiKey1, setApiKey1] = useState("");
  const GOOGLE_PLACES_API_KEY = apiKey1;

  const [homeType, setHomeType] = useState("");
  const [homeSize, setHomeSize] = useState("");
  const [description, setDescription] = useState("");

  const navigate = useNavigate();

  const addressRef = useRef();
  const [is500, setIs500] = useState(window.innerWidth < 500);
  const [is750, setIs750] = useState(window.innerWidth < 750);
  const [is1000, setIs1000] = useState(window.innerWidth < 1000);

  const updateMedia = () => {
    setIs1000(window.innerWidth < 1000);
    setIs750(window.innerWidth < 750);
    setIs500(window.innerWidth < 500);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  }, []);


  useEffect(() => {
    if (auth.currentUser && auth.currentUser.phoneNumber) {
      setPhoneNumber(auth.currentUser.phoneNumber);
    }
    const fetchApiKey = async () => {
      const apiKeyDoc = await getDoc(doc(firestore, 'promenne', 'apikeys'));
      const apiKey = apiKeyDoc.data()["apikeys"]["apikey1"];
      setApiKey1(apiKey);
    };
    fetchApiKey();
  }, [auth.currentUser]);

  const handlePlaceChanged = () => {
    const place = addressRef.current.getPlace();
    if (place && place.address_components) {
      const isAddressInPragueOrCentralBohemianRegion = place.address_components.some(
        (component) =>
          ['locality', 'administrative_area_level_1', 'administrative_area_level_2', 'sublocality_level_1', 'sublocality'].includes(component.types[0]) &&
          (component.long_name.startsWith('Praha') ||
            component.long_name.startsWith('Prague') ||
            component.long_name.startsWith('Hlavní město Praha') ||
            component.long_name.startsWith('Středočeský kraj') ||
            component.long_name.startsWith('Central Bohemian Region')||
            component.long_name.startsWith('Brno') ||
            component.long_name.startsWith('Brno-město') ||
            component.long_name.startsWith('Brno-střed')||
            component.long_name.startsWith('Jihomoravský kraj'))
      );
      if (!isAddressInPragueOrCentralBohemianRegion) {
        alert('Omlouváme se, Tasker je nyní dostupný pouze v Praze a Středočeském kraji.');
      } else {
         // Check for street number or premise
         const hasStreetNumber = place.address_components.some(component => component.types.includes('street_number'));
         const hasPremise = place.address_components.some(component => component.types.includes('premise'));

         if (!hasStreetNumber && !hasPremise) {
           alert('Prosíme, zadejte i číslo popisné');
           return;
         }
        setSelectedAddress(place.formatted_address);
      }
    }
  };


  useEffect(() => {
    if (apiKey1) {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey1}&libraries=places&callback=initAutocomplete`;
      script.async = true;
      document.body.appendChild(script);

      window.initAutocomplete = () => {
        addressRef.current = new window.google.maps.places.Autocomplete(
          document.getElementById('autocomplete-address'),
          { componentRestrictions: { country: 'cz' }, fields: ["formatted_address", "geometry"], types: ["address"] }
        );
        addressRef.current.addListener('place_changed', handlePlaceChanged);
      };

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [apiKey1]);

  const onRegister = async () => {
    setPressed(true);
    const emailIsValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test((email || '').trim());

    if (!email.trim() || !password.trim() || !name.trim()) {
      setNameValid(!!name.trim());
      setEmailValid(!!email.trim());
      setPasswordValid(!!password.trim());
      setConfirmPasswordValid(!!confirmPassword.trim());
      setPressed(false);
      alert("Vyplňte údaje");
      return;
    }

    if (!emailIsValid) {
      setPressed(false);
      alert("Ujistěte se, že email obsahuje '@' a '.'");
      return;
    }

    if (password.length < 6) {
      setPressed(false);
      alert("Upozornění, Heslo musí mít alespoň 6 znaků");
      return;
    }

    try {

      const credential = EmailAuthProvider.credential(email, password);

      const currentUser = auth.currentUser;
      if (!currentUser) {
        setPressed(false);
        alert("No authenticated user found.");
        return;
      }

      await linkWithCredential(currentUser, credential);

      const currentUserUid = currentUser.uid;

      await setDoc(doc(firestore, 'users', currentUserUid), {
        name, email, phoneNumber, currentUserUid, photoURL, ico, dic, companyName, homeSize, homeType, inputAddress: selectedAddress, registrationComplete: true
      }, { merge: true });

      await updateProfile(currentUser, { displayName: name, photoURL: photoURL });

      setRegistrationComplete(true);
      setName('');
      setEmail('');
      setPassword('');
      navigate('/home', { replace: true });
      setPressed(false);
    } catch (error) {
      console.error("Error during registration:", error);
      if (error.code === 'auth/requires-recent-login') {
        setRequiresRecentLogin(true);
        alert("Musíte se přihlásit");
        setPressed(false);
        navigate('/login', { state: { requiresRecentLogin: true, page: "register3" } });
      } else {
        setPressed(false);
        alert("Chybně zadané údaje", error.message);
      }
    }
  };

  const handleButtonPress = (buttonType) => {
    setHomeType(buttonType);
  };

  const getDescription = (size, type) => {
    if (type === 'Byt') {
      if (size <= 40) return 'Malý byt 1+kk-2+kk';
      if (size <= 60) return 'Střední byt 2+kk-3+kk';
      if (size <= 85) return 'Středně velký byt 3+kk-4+kk';
      if (size <= 120) return 'Velký byt 4+kk-5+kk';
      if (size <= 160) return 'Velký byt 5+kk-6+kk';
      if (size <= 200) return 'Velký byt 6+kk-7+kk';
      if (size <= 1000) return 'Obrovský byt 7+kk-10+kk';
    } else if (type === 'Kancelář') {
      if (size <= 30) return 'Malá kancelář';
      if (size <= 300) return 'Velká kancelář';
      if (size <= 3000) return 'Obrovská kancelář';
    } else if (type === 'Rodinný dům') {
      if (size <= 101) return 'Malý dům 2+kk-3+kk';
      if (size <= 200) return 'Středně velký dům 3+kk-4+kk';
      if (size <= 300) return 'Velký dům 4+kk-5+kk';
      if (size <= 400) return 'Extra Velký dům 5+kk-6+kk';
      if (size <= 3000) return 'Mansion 6+kk-12+kk';
    }
    return ''; // Default return value if no conditions are met
  };

  useEffect(() => {
    setDescription(getDescription(parseInt(homeSize, 10), homeType));
  }, [homeSize, homeType]);

  const handleHomeSizeChange = (text) => {
    setHomeSize(text);
  };

  return (
    <div style={{
      padding: 0,
      backgroundColor: '#f8f9fa',
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
    }}>
      <div style={{
        marginTop: 0,
        textAlign: 'center',
        marginBottom: 20,
      }}>
        <h1 style={{
          fontWeight: 'bold',
          fontSize: 32,
          color: '#333',
        }}>Pojďme začít!</h1>
      </div>

      <div style={{
        width: '90%',
        margin: 'auto',
        backgroundColor: 'white',
        paddingLeft: 20,
        paddingRight:20,
        paddingBottom:20,
        borderRadius: 15,
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      }}>
        <h2 style={{
          fontWeight: 'bold',
          fontSize: 24,
          color: '#333',
          marginBottom: 10,
        }}>Registrace: Zadejte své údaje</h2>
        <p style={{
          fontSize: 16,
          color: '#8b9cb5',
          marginBottom: 30,
        }}>Vyplňte níže uvedené informace pro dokončení registrace</p>

        <label style={{
          fontWeight: 'bold',
          fontSize: 16,
          color: '#333',
          marginTop: 20,
          marginBottom: 5,
        }}>Jméno</label>
        <div style={{
          position: 'relative',
          marginBottom: 20,
          display:"flex",
          flexDirection:"row",
          alignItems:"center",
          justifyContent:"center"
        }}>
          <img src={profilImg} alt="profile icon" style={{
            position: 'absolute',
            left: 10,
            top: '50%',
            transform: 'translateY(-50%)',
            width: 20,
            height: 20,
            zIndex:2
          }} />
          <input
            style={{
              width: '100%',
              height: 50,
              paddingLeft: 40,
              borderRadius: 10,
              backgroundColor: '#f2f5f8',
              color: '#333',
              border: nameValid ? 'none' : '1px solid red',
              fontSize: 16,
            }}
            onChange={(e) => setName(e.target.value)}
            placeholder="Jan Tasker"
            autoCapitalize="words"
            type="text"
          />
        </div>

        <label style={{
          fontWeight: 'bold',
          fontSize: 16,
          color: '#333',
          marginTop: 20,
          marginBottom: 5,
        }}>Email</label>
        <div style={{
           position: 'relative',
           marginBottom: 20,
           display:"flex",
           flexDirection:"row",
           alignItems:"center",
           justifyContent:"center"
        }}>
          <img src={emailImg} alt="email icon" style={{
            position: 'absolute',
            left: 10,
            top: '50%',
            transform: 'translateY(-50%)',
            width: 20,
            height: 20,
          }} />
          <input
            style={{
              width: '100%',
              height: 50,
              paddingLeft: 40,
              borderRadius: 10,
              backgroundColor: '#f2f5f8',
              color: '#333',
              border: emailValid ? 'none' : '1px solid red',
              fontSize: 16,
            }}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="jan@tasker.cz"
            autoCapitalize="none"
            type="email"
          />
        </div>

        <label style={{
          fontWeight: 'bold',
          fontSize: 16,
          color: '#333',
          marginTop: 20,
          marginBottom: 5,
        }}>Heslo</label>
        <div style={{
         position: 'relative',
         marginBottom: 20,
         display:"flex",
         flexDirection:"row",
         alignItems:"center",
         justifyContent:"center"
        }}>
          <img src={passwordImg} alt="password icon" style={{
            position: 'absolute',
            left: 10,
            top: '50%',
            transform: 'translateY(-50%)',
            width: 20,
            height: 20,
          }} />
          <input
            style={{
              width: '100%',
              height: 50,
              paddingLeft: 40,
              borderRadius: 10,
              backgroundColor: '#f2f5f8',
              color: '#333',
              border: passwordValid ? 'none' : '1px solid red',
              fontSize: 16,
            }}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Heslo"
            autoCapitalize="none"
            type="password"
          />
        </div>

        <h2 style={{
          fontWeight: 'bold',
          fontSize: 24,
          color: '#333',
          marginTop: 40,
          marginBottom: 20,
          textAlign: 'center',
        }}>Volitelné</h2>
        <label style={{
          fontWeight: 'bold',
          fontSize: 16,
          color: '#333',
          marginTop: 20,
          marginBottom: 5,
        }}>Adresa</label>

        <div style={{
            position:"relative",
                    display:"flex",
                    flexDirection:"row",
                    alignItems:"center",
                    justifyContent:"center"
        }}>
          <img src={locationImg} alt="location icon" style={{
            position: 'absolute',
            left: 10,
            top: '50%',
            transform: 'translateY(-50%)',
            width: 20,
            height: 20,
          }} />
          <input
            id="autocomplete-address"
            type="text"
            placeholder="Zadejte adresu a číslo popisné"
            style={{
              width: '100%',
              height: 50,
              paddingLeft: 40,
              borderRadius: 10,
              backgroundColor: '#f2f5f8',
              color: '#333',
              border: 'none',
              fontSize: is500 ? 10 : 16,
            }}
          />
        </div>

        <div style={{
          display: 'flex',
          alignItems: 'center',
          marginTop: 20,
        }}>
          <label style={{
            fontWeight: 'bold',
            fontSize: 16,
            color: '#333',
            marginRight: 10,
          }}>Typ domácnosti</label>
          <img src={clickBlackImg} alt="click icon" style={{
            width: 20,
            height: 20,
          }} />
        </div>

        <div style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: 10,
        }}>
          <button
            style={{
              flex: 1,
              padding: '12px 0',
              borderWidth: homeType === 'Byt' ? 1 : 0,
              borderColor: homeType === 'Byt' ? '#2ab043' : '#8b9cb5',
              backgroundColor: homeType === 'Byt' ? '#8dff8d' : '#f2f5f8',
              borderRadius: homeType === 'Byt' ? 10 : 0
            }}
            onClick={() => handleButtonPress('Byt')}
          >
            Byt
          </button>
          <button
            style={{
              flex: 1,
              padding: '12px 0',
              borderWidth: homeType === 'Rodinný dům' ? 1 : 0,
              borderColor: homeType === 'Rodinný dům' ? '#2ab043' : '#8b9cb5',
              backgroundColor: homeType === 'Rodinný dům' ? '#8dff8d' : '#f2f5f8',
              borderRadius: homeType === 'Rodinný dům' ? 10 : 0
            }}
            onClick={() => handleButtonPress('Rodinný dům')}
          >
            Rodinný dům
          </button>
          <button
            style={{
              flex: 1,
              padding: '12px 0',
              borderWidth: homeType === 'Kancelář' ? 1 : 0,
              borderColor: homeType === 'Kancelář' ? '#2ab043' : '#8b9cb5',
              backgroundColor: homeType === 'Kancelář' ? '#8dff8d' : '#f2f5f8',
              borderRadius: homeType === 'Kancelář' ? 10 : 0
            }}
            onClick={() => handleButtonPress('Kancelář')}
          >
            Kancelář
          </button>
        </div>

        <label style={{
          fontWeight: 'bold',
          fontSize: 16,
          color: '#333',
          marginTop: 20,
          marginBottom: 5,
        }}>Velikost domácnosti v m²</label>
        <p style={{
          fontWeight: 'bold',
          fontSize: 14,
          color: '#8b9cb5',
          marginTop: 5,
          marginBottom: 10,
        }}>
          {description || 'Odhad'}
        </p>
        <div style={{
          position: 'relative',
          marginBottom: 20,
          display:"flex",
          flexDirection:"row",
          alignItems:"center",
          justifyContent:"center"
        }}>
          <img src={sizeImg} alt="size icon" style={{
            position: 'absolute',
            left: 10,
            top: '50%',
            transform: 'translateY(-50%)',
            width: 20,
            height: 20,
          }} />
          <input
            style={{
              width: '100%',
              height: 50,
              paddingLeft: 40,
              paddingRight: 40,
              borderRadius: 10,
              backgroundColor: '#f2f5f8',
              color: '#333',
              border: 'none',
              fontSize: 16,
            }}
            placeholder="Zadejte velikost domu"
            onChange={(e) => handleHomeSizeChange(e.target.value)}
            value={homeSize}
            type="number"
          />
          <span style={{
            position: 'absolute',
            right: 15,
            top: '50%',
            transform: 'translateY(-50%)',
            color: '#8b9cb5',
          }}>m²</span>
        </div>

        <button onClick={() => setShowCompanyInputs(!showCompanyInputs)} style={{
          display: 'flex',
          alignItems: 'center',
          marginTop: 20,
          border: 'none',
          background: 'none',
          cursor: 'pointer',
        }}>
          <div style={{
            height: 20,
            width: 20,
            borderWidth: 2,
            borderStyle: 'solid',
            marginRight: 10,
            backgroundColor: showCompanyInputs ? '#00da00' : 'white',
            borderColor: showCompanyInputs ? '#00da00' : '#8b9cb5',
            borderRadius: 4,
          }} />
          <span style={{
            color: '#555',
            fontSize: 16,
          }}>Doplnit firemní údaje</span>
        </button>

        {showCompanyInputs && (
          <>
            <input
              style={{
                width: '100%',
                height: 50,
                paddingLeft: 15,
                borderRadius: 10,
                backgroundColor: '#f2f5f8',
                color: '#333',
                border: 'none',
                fontSize: 16,
                marginTop: 20,
              }}
              placeholder="IČO"
              onChange={(e) => setIco(e.target.value)}
              type="text"
            />
            <input
              style={{
                width: '100%',
                height: 50,
                paddingLeft: 15,
                borderRadius: 10,
                backgroundColor: '#f2f5f8',
                color: '#333',
                border: 'none',
                fontSize: 16,
                marginTop: 20,
              }}
              placeholder="DIČ (pouze plátci DPH)"
              onChange={(e) => setDic(e.target.value)}
              type="text"
            />
            <input
              style={{
                width: '100%',
                height: 50,
                paddingLeft: 15,
                borderRadius: 10,
                backgroundColor: '#f2f5f8',
                color: '#333',
                border: 'none',
                fontSize: 16,
                marginTop: 20,
              }}
              placeholder="Název společnosti"
              onChange={(e) => setCompanyName(e.target.value)}
              type="text"
            />
          </>
        )}
      </div>

      <button onClick={() => onRegister()} style={{
        alignSelf: 'center',
        alignItems: 'center',
        padding: '15px 0',
        marginTop: 40,
        marginBottom: 20,
        width: '90%',
        backgroundColor: '#00da00',
        borderRadius: 10,
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        border: 'none',
        cursor: 'pointer',
        transition: 'background-color 0.3s',
      }}>
        <span style={{
          color: 'white',
          fontSize: 20,
          fontWeight: 'bold',
        }}>Dokončit registraci</span>
        <img src={pressed ? loadingGif : clickImg} alt="click icon" style={{
          width: 35,
          height: 35,
          marginLeft: 15,
        }} />
        <img src={pressed ? loadingGif : nextImg} alt="loading icon" style={{
          position: 'absolute',
          right: 20,
          width: 20,
          height: 20,
        }} />
      </button>
    </div>
  );
}

export default Register3;
