import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getDatabase, ref, onValue, off } from 'firebase/database';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import config from '../config';
import loadingImg from '../images/loadingImage.gif';
import xImg from "../images/Xkopopup.png";
import Modal from '../components/appModal';

const firebaseConfig = {
  apiKey: config.firebase.apiKey,
  authDomain: config.firebase.authDomain,
  projectId: config.firebase.projectId,
  storageBucket: config.firebase.storageBucket,
  messagingSenderId: config.firebase.messagingSenderId,
  appId: config.firebase.appId,
  databaseURL: config.firebase.databaseURL,
  measurementId: config.firebase.measurementId,
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const database = getDatabase(app);
const firestore = getFirestore(app);
const stripePromise = loadStripe(config.stripePublicKey);

const TEXT_WIDTH = 30;
const STEP_SIZE = 5;
const MAX_VALUE = 1000;

const EmployeeWork = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentUserUid = auth.currentUser?.uid;
  const { orderId } = location.state || {};
  const scrollViewRef = useRef(null);
  const [unreadCount, setUnreadCount] = useState(0);
  const [duration, setDuration] = useState(0);
  const [remainingTime, setRemainingTime] = useState(0);
  const [key, setKey] = useState(0);
  const [sliderValue, setSliderValue] = useState(0);
  const [price, setPrice] = useState("");
  const [savedCard, setSavedCard] = useState("");
  const [paymentSheetInProgress, setPaymentSheetInProgress] = useState(false);
  const [paymentDatabaseUpdateInProgress, setPaymentDatabaseUpdateInProgress] = useState(false);
  const [stripeCustomerId, setStripeCustomerId] = useState("");
  const [showAddTimeModal, setShowAddTimeModal] = useState(false);
  const [formattedRemainingTime, setFormattedRemainingTime] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchOrderData = async () => {
      if (!currentUserUid || !orderId) return;
      const orderRef = ref(database, `workInProgress/${currentUserUid}/${orderId}`);
      onValue(orderRef, (snapshot) => {
        const orderData = snapshot.val();
        if (orderData) {
          const jobStartTime = orderData.jobStartTime;
          const jobEndTime = orderData.jobEndTime;
          const currentTime = Date.now();

          const totalDuration = Math.floor((jobEndTime - jobStartTime) / 1000);
          const initialRemainingTime = Math.floor((jobEndTime - currentTime) / 1000);

          setDuration(totalDuration);
          setRemainingTime(initialRemainingTime);
          setStripeCustomerId(orderData.stripeCustomerId);
          setPrice(orderData.price);
          setKey(prevKey => prevKey + 1);
        }
      });

      return () => off(orderRef);
    };
    fetchOrderData();

    const fetchSavedCard = async () => {
      if (!currentUserUid) return;
      const userDocRef = doc(firestore, 'users', currentUserUid);
      const userDoc = await getDoc(userDocRef);
      const stripeCustomerId = userDoc.data()?.stripeCustomerId;
      if (!stripeCustomerId) return;
      const response = await axios.post(`${config?.firebaseFunctionEndpoint}/listPaymentMethods`, {
        customerId: stripeCustomerId,
      });
      const paymentMethods = response.data?.data;
      if (paymentMethods) {
        setSavedCard(paymentMethods[0]);
      }
    };
    fetchSavedCard();
  }, [currentUserUid, orderId]);

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${String(hours).padStart(2, '0')}h:${String(minutes).padStart(2, '0')}min`;
  };

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      setRemainingTime((prevTime) => {
        if (prevTime <= 0) {
          clearInterval(countdownInterval);
          return 0;
        }
        const newTime = prevTime - 1;
        setFormattedRemainingTime(formatTime(newTime));
        return newTime;
      });
    }, 1000);

    return () => clearInterval(countdownInterval);
  }, []);

  async function updateDataAfterAdditionalPayment() {
    const functionUrl = `${config?.firebaseFunctionEndpoint}/updateDataAfterAdditionalPayment`;
    try {
      const response = await axios.post(functionUrl, {
        orderId: orderId,
        userId: currentUserUid,
        sliderValue: sliderValue,
        dynamicPrice: dynamicPrice
      });
      return response;
    } catch (error) {
      console.error('Error calling cloud function:', error);
    }
  }

  async function handlePayment(useSavedCard = true) {
    if (dynamicPrice <= 0) {
      alert("Vyberte kolik času si přejete dokoupit");
      return;
    }
    setPaymentSheetInProgress(true);
    try {
      const paymentIntentResponse = await axios.post(`${config?.firebaseFunctionEndpoint}/createPaymentIntent`, {
        price: dynamicPrice,
        customerId: stripeCustomerId,
        savedCard: useSavedCard ? savedCard?.id : null,
        instantPayment: false,
        tipPayment: true
      });
      const { clientSecret, status, id } = paymentIntentResponse.data;
      if (status === 'requires_capture') {
        setPaymentDatabaseUpdateInProgress(true);
        const response = await updateDataAfterAdditionalPayment();
        if (response?.data.success === true) {
          setPaymentDatabaseUpdateInProgress(false);
          setShowAddTimeModal(false);
          setPaymentSheetInProgress(false);
          navigate('/employeeWork', { state: { orderId } });
          alert("Čas úspěšně přidán");
        }
        return;
      }
    } catch (error) {
      setPaymentDatabaseUpdateInProgress(false);
      setPaymentSheetInProgress(false);
    } finally {
      setPaymentSheetInProgress(false);
      setPaymentDatabaseUpdateInProgress(false);
      setShowAddTimeModal(false);
    }
  }

  const calculatePrice = () => {
    const basePrice = price / (duration / 60);
    const calculatedPrice = sliderValue * basePrice;
    return calculatedPrice.toFixed(0);
  };

  const dynamicPrice = calculatePrice();

  const handleScroll = (event) => {
    const scrollPosition = event.target.scrollLeft;
    const numberOfHiddenItems = Math.floor(scrollPosition / TEXT_WIDTH);
    const newValue = numberOfHiddenItems * STEP_SIZE;
    if (newValue !== sliderValue) {
      window.navigator.vibrate(10);
    }
    if (newValue >= 0) {
      setSliderValue(newValue);
    }
  };

  useEffect(() => {
    const chatRef = ref(database, `workInProgress/${currentUserUid}/${orderId}/chat`);
    const onChatUpdate = (snapshot) => {
      const chatData = snapshot.val();
      if (chatData) {
        const unreadMessages = Object.values(chatData).filter(
          (message) => message.senderId !== currentUserUid && !message.isRead
        );
        setUnreadCount(unreadMessages.length);
      }
    };
    onValue(chatRef, onChatUpdate);
    return () => {
      off(chatRef, onChatUpdate);
    };
  }, [currentUserUid, orderId]);

  const handleChatPress = () => {
    if (!orderId) return;
    navigate('/chat', { state: { orderId } });
  };

  if (!duration || paymentDatabaseUpdateInProgress) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <img src={loadingImg} alt="loading" style={{ width: 100, height: 100 }} />
      </div>
    );
  }

  return (
    <Elements stripe={stripePromise}>
      <div style={styles.container}>
      <Modal isOpen={isModalOpen} onClose={()=>{setIsModalOpen(false)}} />

        <div style={{ flex: 0.24 }}></div>
        <p style={{ color: "black", fontWeight: "bold", fontSize: 28, textAlign: "center" }}>Práce právě {"\n"}<span style={{ color: "lightgreen" }}>probíhá</span></p>
        <div style={{ flex: 0.2 }}></div>
        <div style={{    width: 200,
          height: 200,
          backgroundColor: "#F2F5F8",
          borderRadius: "50%",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          textAlign: "center",
          flexDirection:"column"}}>
          <p style={{ fontSize: 10, color: 'gray' }}>ZBÝVAJÍCÍ ČAS</p>
          <p style={{ fontSize: 20, fontWeight: 'bold', color: 'black' }}>
            {formattedRemainingTime}
          </p>
        </div>
        <button
          style={{ flexDirection: "row", width: "90%", border:"none", alignSelf: "center", alignItems: "center", justifyContent: "center", marginTop: 10, paddingTop: 15, paddingBottom: 15, backgroundColor: "#00da00", borderRadius: 15, cursor: "pointer" }}
          onClick={() => { setIsModalOpen(true) }}
        >
          <img style={{ width: 20, height: 20 }} src={require("../images/bilejplus.png")} />
          <p style={{ color: "white", fontSize: 13, textAlign: "center", marginLeft: 10, fontWeight: "bold" }}>{`Prodloužit čas`}</p>
        </button>
        <button
          style={{ flexDirection: "row", border:"none", width: "90%", alignSelf: "center", alignItems: "center", justifyContent: "center", marginTop: 15, paddingTop: 15, paddingBottom: 15, backgroundColor: "#F2F5F8", borderRadius: 15, cursor: "pointer" }}
          onClick={()=>{setIsModalOpen(true)}}
        >
          <img style={{ width: 20, height: 20 }} src={require("../images/Chatgrey.png")} />
          <p style={{ color: "gray", fontSize: 13, textAlign: "center", marginLeft: 10, fontWeight: "bold", textDecoration: "underline" }}>{`Otevřít chat s dodavatelem (${unreadCount})`} </p>
        </button>
        {showAddTimeModal && (
          <div style={styles.modalOverlay}>
            <div style={styles.modalContent}>
              <button
                style={styles.closeButton}
                onClick={() => setShowAddTimeModal(false)}
              >
                X
              </button>
              <div style={{ backgroundColor: "#4ad52f", alignItems: "center" }}>
                <div style={{ backgroundColor: "white", alignItems: "center", borderRadius: 50, justifyContent: "center", padding: "10px 20px" }}>
                  <p style={{ color: "green" }}>Navýšit čas</p>
                </div>
                <div style={{ width: "80%", alignItems: "center", backgroundColor: "white", borderRadius: 10, paddingTop: 20, paddingBottom: 20, marginTop: 15 }}>
                  <p style={{ color: "black", fontSize: 34, fontWeight: "bold" }}>{Math.floor(sliderValue / 60)}h:{sliderValue % 60}min</p>
                  <p style={{ color: "black", fontSize: 14, marginTop: 20, fontWeight: "bold" }}>{dynamicPrice} CZK</p>
                </div>
                <div
                  ref={scrollViewRef}
                  onScroll={handleScroll}
                  style={{ height: 50, width: "80%", marginTop: 40, overflowX: "scroll", whiteSpace: "nowrap" }}
                >
                  {[...Array(MAX_VALUE / STEP_SIZE)].map((_, index) => (
                    <span key={index} style={{ fontSize: 40, color: "white" }}>
                      |
                    </span>
                  ))}
                </div>
                <img src={require("../images/swipeicon.png")} style={{ position: "absolute", bottom: 300, width: 50, height: 50 }} />
                <p style={{ textAlign: "center", color: "white", fontSize: 14, marginTop: 20, fontWeight: "bold", position: "absolute", bottom: 220 }}>Vyberte si kolik času navíc si přejete přidat {"\n"}ke své službě. Posuňte výše a vyberte</p>
                <div style={{ position: "absolute", bottom: 10, marginBottom: 20, alignItems: "center", alignSelf: "center", width: "100%" }}>
                  {savedCard && (
                    <div style={{ width: "100%", alignItems: "center" }}>
                      <button
                        style={{ width: "80%", marginTop: 5, marginBottom: 0, paddingLeft: 10, paddingRight: 10, paddingTop: 10, paddingBottom: 10, backgroundColor: "white", borderRadius: 5, display: "flex", justifyContent: "space-between", alignItems: "center" }}
                        onClick={() => handlePayment(false)}
                      >
                        <p style={{ color: "black", textAlign: "center" }}>{` ${savedCard.card.brand} **** ${savedCard.card.last4}`}</p>
                        <p style={{ color: "black", textAlign: "center" }}>{paymentSheetInProgress ? "Načítání..." : `>`}</p>
                      </button>
                    </div>
                  )}
                  <button
                    style={{ marginTop: 5, width: "80%", height: 80, justifyContent: "center", backgroundColor: "green", borderRadius: 10, display: "flex", alignItems: "center" }}
                    onClick={handlePayment}
                  >
                    <p style={{ color: "white", textAlign: "center", fontWeight: "bold", fontSize: 18 }}>{paymentSheetInProgress ? "Načítání..." : `NAVÝŠIT ČAS`}</p>
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Elements>
  );
};

const styles = {
  container: {
    flex: 1,
    backgroundColor: "white",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
  centeredText: {
    width: 200,
    height: 200,
    backgroundColor: "#F2F5F8",
    borderRadius: "50%",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    textAlign: "center",
  },
  modalOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1000,
  },
  modalContent: {
    backgroundColor: 'white',
    borderRadius: '10px',
    padding: '20px',
    maxWidth: '500px',
    width: '80%',
    position: 'relative',
  },
  closeButton: {
    position: 'absolute',
    top: 10,
    right: 10,
    backgroundColor: 'white',
    borderRadius: '50%',
    height: 40,
    width: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    border: 'none',
  },
};

export default EmployeeWork;
