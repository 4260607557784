import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import { UserContext, UserProvider } from './UserContext';
import Register3 from './pages/register3';
import Home from './pages/homePage';
import Register from './pages/register';
import Login from './pages/login';
import ConfirmingPage from './pages/confirmingPage';
import EmployeeRoute from './pages/employeeRoute';
import EmployeeArrived from './pages/employeeArrived';
import EmployeeWork from './pages/employeeWork';
import FinishedWork from './pages/finishedWork';
import loadingImg from './images/loadingImage.gif';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { getDatabase, ref, onValue, off } from 'firebase/database';
import config from './config';

const stripePromise = loadStripe(config.stripePublicKey);

function App() {
  const { user, loading, registrationComplete } = useContext(UserContext);

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <img src={loadingImg} alt="loading" style={{ width: 100, height: 100 }} />
      </div>
    );
  }

  return (
    <Router>
      <AppRoutes user={user} registrationComplete={registrationComplete} />
    </Router>
  );
}

function AppRoutes({ user, registrationComplete }) {
  const navigate = useNavigate();
  const [currentOrder, setCurrentOrder] = useState(null);

  useEffect(() => {
    if (user) {
      const database = getDatabase();
      const workInProgressRef = ref(database, `workInProgress/${user.uid}/`);

      const handleWorkInProgressSnapshot = (snapshot) => {
        const orders = snapshot.val();
        if (orders) {
          const orderId = Object.keys(orders)[0];
          const orderData = orders[orderId];
          setCurrentOrder({ orderId, ...orderData });

          let targetPage;
          switch (orderData.page) {
            case 'ConfirmingPage':
              targetPage = '/confirmingPage';
              break;
            case 'EmployeeRoute':
              targetPage = '/employeeRoute';
              break;
            case 'EmployeeArrived':
              targetPage = '/employeeArrived';
              break;  
            case 'EmployeeWork':
              targetPage = '/employeeWork';
              break; 
            case 'FinishedWork':
              targetPage = '/finishedWork';
              break;            
            default:
              targetPage = '/home';
          }
          navigate(targetPage, { state: { orderId, clientId: orderData.currentUserUid } });
        } else {
          setCurrentOrder(null);
        }
      };

      onValue(workInProgressRef, handleWorkInProgressSnapshot);

      return () => {
        off(workInProgressRef, 'value', handleWorkInProgressSnapshot);
      };
    }
  }, [user, navigate]);

  return (
    <Routes>
      <Route path="/register" element={<Register />} />
      <Route 
        path="/register3" 
        element={
          user && !registrationComplete 
            ? <Register3 /> 
            : <Navigate to={user ? "/home" : "/register"} />
        } 
      />
      <Route 
        path="/home" 
        element={
          user && registrationComplete 
            ? <Home /> 
            : <Navigate to={user ? "/register3" : "/register"} />
        } 
      />
      <Route path="/login" element={<Login />} />
      <Route 
        path="*" 
        element={
          user 
            ? (registrationComplete ? <Navigate to="/home" /> : <Navigate to="/register3" />)
            : <Navigate to="/register" />
        } 
      />
      <Route path="/confirmingPage" element={<ConfirmingPage />} />
      <Route path="/employeeRoute" element={<EmployeeRoute />} />
      <Route path="/employeeArrived" element={<EmployeeArrived />} />
      <Route path="/employeeWork" element={<EmployeeWork />} />
      <Route path="/finishedWork" element={<FinishedWork />} />
    </Routes>
  );
}

function AppWrapper() {
  return (
    <UserProvider>
      <Elements stripe={stripePromise}>
        <App />
      </Elements>
    </UserProvider>
  );
}

export default AppWrapper;
