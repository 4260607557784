import React, { useState, useEffect, useRef } from 'react';
import { initializeApp } from 'firebase/app';
import { getAuth, signOut } from 'firebase/auth';
import { getDatabase, ref, onValue, off } from 'firebase/database';
import { getFirestore, doc, getDoc, collection, addDoc } from 'firebase/firestore';
import { set } from 'firebase/database';
import { getAnalytics } from 'firebase/analytics';
import { Elements, CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from '../config';
import clickBlackImg from '../images/clickblack.png';
import clickImg from '../images/clickwhite.png';
import '../styles/homePage.css';
import sizeImg from '../images/rozmeryblack.png';
import locationImg from '../images/locationblack.png';
import sipkaIkonkaKruhImg from '../images/navigationgreen.png'; // Add the correct path to your image
import loadingGif from '../images/7777.gif'; // Add the correct path to your loading gif
import emailImg from '../images/mailblack.png';
import Header from '../components/header';
import Modal from '../components/appModal';

const firebaseConfig = {
  apiKey: config.firebase.apiKey,
  authDomain: config.firebase.authDomain,
  projectId: config.firebase.projectId,
  storageBucket: config.firebase.storageBucket,
  messagingSenderId: config.firebase.messagingSenderId,
  appId: config.firebase.appId,
  databaseURL: config.firebase.databaseURL,
  measurementId: config.firebase.measurementId,
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const database = getDatabase(app);
const firestore = getFirestore(app);
const analytics = getAnalytics(app);
const stripePromise = loadStripe(config.stripePublicKey, { locale: 'cs' });

const CheckoutPage = () => {
  const navigate = useNavigate();
  const addressRef = useRef();
  const currentUserUid = auth.currentUser?.uid;
  const [loading, setLoading] = useState(true);
  const [selectedServiceTitle, setSelectedServiceTitle] = useState('Úklid');
  const [services, setServices] = useState([]);
  const [service, setService] = useState('');
  const [serviceTime, setServiceTime] = useState('');
  const [waitingFor, setWaitingFor] = useState('');
  const [couponCode, setCouponCode] = useState('');
  const [totalPrice, setTotalPrice] = useState('');
  const [description, setDescription] = useState("");
  const [homeType, setHomeType] = useState("");
  const [homeSize, setHomeSize] = useState("");
  const [apiKey1, setApiKey1] = useState("");
  const [phoneNumber, setPhoneNumber] = useState('');
  const [selectedAddress, setSelectedAddress] = useState("");
  const [inputAddress, setInputAddress] = useState("");
  const [workersFound, setWorkersFound] = useState(false);
  const [selectedOption, setSelectedOption] = useState("now");
  const [longestETA, setLongestETA] = useState(null);
  const [pet, setPet] = useState(null);
  const [equipment, setEquipment] = useState(null);
  const [carType, setCarType] = useState("");
  const [groundSize, setGroundSize] = useState("");
  const [groundVapeSize, setGroundVapeSize] = useState("");
  const [missingInfo, setMissingInfo] = useState('');
  const [location, setLocation] = useState(null);
  const [assignedEmployees, setAssignedEmployees] = useState({});
  const [calculatingPrice, setCalculatingPrice] = useState(false);
  const [priceWithoutTax, setPriceWithoutTax] = useState('');
  const [workers, setWorkers] = useState(0);
  const [driveRate, setDriveRate] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [discountFactor, setDiscountFactor] = useState(1);
  const [surchargePercentage, setSurchargePercentage] = useState(0);
  const [findingEmployee, setFindingEmployee] = useState(false);
  const [email, setEmail] = useState('');
  const [emailInput, setEmailInput] = useState('');
  const [emailValid, setEmailValid] = useState(true);
  const [addOns, setAddOns] = useState('');
  const [stripeCustomerId, setStripeCustomerId] = useState('');
  const [paymentSheetInProgress, setPaymentSheetInProgress] = useState(false);
  const [paymentDatabaseUpdateInProgress, setPaymentDatabaseUpdateInProgress] = useState(false);
  const [paymentIntentId, setPaymentIntentId] = useState(null);
  const [clientName, setClientName] = useState('');
  const [savedCard, setSavedCard] = useState('');
  const [clientSecret, setClientSecret] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [is500, setIs500] = useState(window.innerWidth < 500);
  const [is750, setIs750] = useState(window.innerWidth < 750);
  const [is1000, setIs1000] = useState(window.innerWidth < 1000);
  const updateMedia = () => {
    setIs1000(window.innerWidth < 1000);
    setIs750(window.innerWidth < 750);
    setIs500(window.innerWidth < 500);
  };
  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  }, []);

  const stripe = useStripe();
  const elements = useElements();

  const [clientData, setClientData] = useState({
    task: selectedServiceTitle,
    waitingFor: null,
    inputAddress: null,
    selectedAddress: null,
    homeSize: null,
    homeType: null,
    pet: null,
    equipment: null,
    carType: null,
    groundSize: null,
    groundVapeSize: null,
  });

  const requiredInfoByTask = {
    'Úklid': ['homeSize', 'homeType', 'pet', 'equipment', "waitingFor"],
    'Okna': ['homeSize', 'homeType', "waitingFor"],
    'Zahrada': ['groundSize', "waitingFor"],
    'Žehlení': ["waitingFor"],
    'Mytí auta': ['carType', "waitingFor"],
    'Vysokotlaké čištění': ['groundVapeSize', "waitingFor"],
  };

  const updateClientData = (field, value) => {
    setClientData(prevData => ({
      ...prevData,
      [field]: value,
    }));
  };

  const debounceAsync = (func, wait) => {
    let timeout;
    const debounced = (...args) =>
      new Promise((resolve, reject) => {
        const later = () => {
          clearTimeout(timeout);
          func(...args)
            .then(resolve)
            .catch(reject);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
      });
    debounced.cancel = () => {
      clearTimeout(timeout);
    };
    return debounced;
  };

  useEffect(() => {
    const updateSearchingClients = async () => {
      const clientRef = ref(database, `searchingClients/${currentUserUid}`);
      const clientData = {
        currentUserUid: currentUserUid,
        webClient: true
      };
      await set(clientRef, clientData);
    };
    updateSearchingClients();

    const getClientInfo = async () => {
      const snapshot = await getDoc(doc(firestore, 'users', currentUserUid));
      const data = snapshot.data();
      if (data && data?.stripeCustomerId) {
        setStripeCustomerId(data?.stripeCustomerId);
        setEmail(data?.email);
        setEmailInput(data?.email);
        setClientName(data?.name);
      }
    };
    getClientInfo();
  }, []);

  useEffect(() => {
    const fetchServices = async () => {
      try {
        if(!waitingFor){
          setServices([]);
        }
        const snapshot = await getDoc(doc(firestore, 'promenne', 'serviceTimes'));
        const data = snapshot.data();
        if (!waitingFor && data && data[selectedServiceTitle]) {
          setServices(data[selectedServiceTitle]);
        }
        if (waitingFor) {
          const matchedService = data[selectedServiceTitle]?.find((service) => service.mainTitle === waitingFor);
          if (matchedService) {
            setService(matchedService);
          } else {
            setService("");
          }
        }
      } catch (error) {
        console.error('Error fetching services:', error);
      }
    };

    fetchServices();
  }, [currentUserUid, selectedServiceTitle, waitingFor]);

  useEffect(() => {
    if (auth.currentUser && auth.currentUser.phoneNumber) {
      setPhoneNumber(auth.currentUser.phoneNumber);
    }
    const fetchApiKey = async () => {
      const apiKeyDoc = await getDoc(doc(firestore, 'promenne', 'apikeys'));
      const apiKey = apiKeyDoc.data()["apikeys"]["apikey1"];
      setApiKey1(apiKey);
    };
    fetchApiKey();
  }, [auth.currentUser]);

  useEffect(() => {
    if (apiKey1) {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey1}&libraries=places&callback=initAutocomplete`;
      script.async = true;
      document.body.appendChild(script);

      window.initAutocomplete = () => {
        addressRef.current = new window.google.maps.places.Autocomplete(
          document.getElementById('autocomplete-address'),
          { componentRestrictions: { country: 'cz' }, fields: ["formatted_address", "geometry", "address_component"], types: ["address"] }
        );
        addressRef.current.addListener('place_changed', handlePlaceChanged);
      };

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [apiKey1]);

  useEffect(() => {
    const addressToLat = async () => {
      if (inputAddress || selectedAddress) {
        let addressToGeocode = inputAddress;
        if (selectedAddress) {
          addressToGeocode = selectedAddress;
        }
        const geoCodeData = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURI(addressToGeocode)}&key=${apiKey1}`
        );
        const geoCodeResult = await geoCodeData.json();
        const lat = geoCodeResult.results[0].geometry.location.lat;
        const lon = geoCodeResult.results[0].geometry.location.lng;
        const location = { lat, lon };
        setLocation(location);
      }
    };
    addressToLat();
  }, [inputAddress, selectedAddress]);

  const handlePlaceChanged = () => {
    const place = addressRef.current.getPlace();
    if (place && place.address_components) {
      const isAddressInPragueOrCentralBohemianRegion = place.address_components.some(
        (component) =>
          ['locality', 'administrative_area_level_1', 'administrative_area_level_2', 'sublocality_level_1', 'sublocality'].includes(component.types[0]) &&
          (component.long_name.startsWith('Praha') ||
            component.long_name.startsWith('Prague') ||
            component.long_name.startsWith('Hlavní město Praha') ||
            component.long_name.startsWith('Středočeský kraj') ||
            component.long_name.startsWith('Central Bohemian Region')||
            component.long_name.startsWith('Brno') ||
            component.long_name.startsWith('Brno-město') ||
            component.long_name.startsWith('Brno-střed')||
            component.long_name.startsWith('Jihomoravský kraj'))
      );
      if (!isAddressInPragueOrCentralBohemianRegion) {
        alert('Omlouváme se, Tasker je nyní dostupný pouze v Praze a Středočeském kraji.');
      } else {
         // Check for street number or premise
         const hasStreetNumber = place.address_components.some(component => component.types.includes('street_number'));
         const hasPremise = place.address_components.some(component => component.types.includes('premise'));

         if (!hasStreetNumber && !hasPremise) {
           alert('Prosíme, zadejte i číslo popisné');
           return;
         }
        updateClientData('selectedAddress', place.formatted_address);
        setSelectedAddress(place.formatted_address);
      }
    }
  };

  async function callFindEmployeeCloud() {
    if (!selectedServiceTitle) return;
    const requiredInfo = requiredInfoByTask[selectedServiceTitle];
    const missingInfo = requiredInfo?.filter(param => clientData[param] === null || clientData[param] === undefined);
    if (missingInfo.length === 0) {
      try {
        const idToken = await auth.currentUser.getIdToken(true);
        const response = await axios.post(`${config?.firebaseFunctionEndpoint}/findEmployeesForClient`, {
          workers,
          equipment,
          waitingFor,
          location,
          currentUserUid,
          serviceTime
        }, {
          headers: {
            Authorization: `Bearer ${idToken}`
          }
        });
        if (response) {
          setFindingEmployee(false);
        }
      } catch (error) {
        console.error('Error calling cloud function:', error);
      }
    }
  }
  const debouncedCallFindEmployeeCloud = debounceAsync(callFindEmployeeCloud, 1000);


  async function verifyStillMatchingOnEmployeeDatabaseChange(employeeId, currentUserUid, equipment, waitingFor, location) {
    const functionUrl = `${config?.firebaseFunctionEndpoint}/verifyStillMatchingOnEmployeeDatabaseChange`;
    try {
      const response = await axios.post(functionUrl, {
        employeeId: employeeId,
        currentUserUid: currentUserUid,
        equipment: equipment,
        waitingFor: waitingFor,
        location: location,
      });
      return response;
    } catch (error) {
      console.error('Error calling cloud function2:', error);
    }
  }

  async function calculatePrice() {
    if (!selectedServiceTitle) return;
    const requiredInfo = requiredInfoByTask[selectedServiceTitle];
    const missingInfo = requiredInfo?.filter(param => clientData[param] === null || clientData[param] === undefined);
    if (missingInfo.length > 0 || !currentUserUid || !serviceTime || !discountFactor || !selectedServiceTitle || (selectedOption !== 'later' && !assignedEmployees) || !location) {
      return;
    }
    setTotalPrice('');
    setCalculatingPrice(true);
    const functionUrl = `${config?.firebaseFunctionEndpoint}/calculatePrice`;
    try {
      const idToken = await auth.currentUser.getIdToken(true);
      const response = await axios.post(functionUrl, {
        currentUserUid: currentUserUid,
        assignedEmployees: assignedEmployees,
        serviceTime: serviceTime,
        equipment: equipment,
        discountFactor: discountFactor,
        task: selectedServiceTitle,
        pet: pet,
        location: location,
        addOns: addOns,
        selectedOption: selectedOption,
      }, {
        headers: {
          Authorization: `Bearer ${idToken}`
        }
      });
      const data = await response.data;

      setTotalPrice(data.totalPrice);
      setPriceWithoutTax(data.priceWithoutTax);
      setDriveRate(data.driveRate);
      setDiscount(data.discount);
      setSurchargePercentage(data.surchargePercentage); // Set the surcharge percentage state
      setCalculatingPrice(false);
    } catch (error) {
      console.error('Error calling cloud function3:', error);
      setCalculatingPrice(false);
    }
  }
  const debouncedCalculatePrice = debounceAsync(calculatePrice, 1000);

  const updateDatabaseAfterPayment = async (paymentIntentId = null) => {
    const functionUrl = `${config?.firebaseFunctionEndpoint}/updateDatabaseAfterPayment`;
    try {
      const idToken = await auth.currentUser.getIdToken(true);
      const response = await axios.post(functionUrl, {
        addOns: addOns ? addOns : {},
        assignedEmployees: assignedEmployees ? assignedEmployees : null,
        currentUserUid: currentUserUid,
        equipment: equipment,
        employer: Object.values(assignedEmployees)[0]?.employer ? Object.values(assignedEmployees)[0]?.employer : null,
        waitingFor: waitingFor,
        webOrder: true,
        location: location,
        pet: pet,
        task: selectedServiceTitle,
        serviceTime: serviceTime && workersFound > 1 ? minutesToHours(toMinutes(serviceTime) / workersFound) : serviceTime,
        serviceTimeOriginal: serviceTime,
        discountFactor: discountFactor,
        couponCode: couponCode,
        inputAddress: selectedAddress || inputAddress,
        homeSize: homeSize,
        homeType: homeType,
        groundSize: groundSize,
        groundVapeSize: groundVapeSize,
        carType: carType,
        price: totalPrice,
        priceWithoutTax: priceWithoutTax,
        paymentIntentId: paymentIntentId,
        discount: discount,
        driveRate: driveRate,
        selectedOption: selectedOption,
        stripeCustomerId: stripeCustomerId,
        futureOrderStartTime: null,
        workers: workers,
        clientName: clientName,
        clientEmail: email,
      }, {
        headers: {
          Authorization: `Bearer ${idToken}`
        }
      });

      await addDoc(collection(firestore, "mail"), {
        to: "info@tasker.cz",
        message: {
          subject: "Klient udělal weborder",
          text: "Klient udělal weborder",
          html: `Klient udělal weborder: Do budoucna: ${selectedOption === "later" ? "Ano" : "Ne"}, Cena: ${totalPrice}, Id usera: ${currentUserUid}, Adresa: ${selectedAddress || inputAddress}, Sleva: ${discount}, Velikost slevy: ${discountFactor}, Jmeno klienta: ${clientName}, Email: ${email}, Task: ${waitingFor}, Velikost: ${homeSize}`,
        },
      });

      setPaymentDatabaseUpdateInProgress(false);
      return response;
    } catch (error) {
      setPaymentDatabaseUpdateInProgress(false);
      alert("Error", error.message);
      console.error('Error calling cloud function:', error);
    }
  };

  async function handlePayment(useSavedCard = true) {
    if (!totalPrice) {
      alert('Cena není dostupná');
      return;
    }

    if (discountFactor === "0") {
      try {
        setPaymentDatabaseUpdateInProgress(true);
        const response = await updateDatabaseAfterPayment();
        if (response?.data.success === true) {
          const orderId = response.data.orderId;
          setPaymentDatabaseUpdateInProgress(false);
          setPaymentSheetInProgress(false);
          alert('Objednávka úspěšně vytvořena');
          if (selectedOption === 'now') {
            navigate('/confirmingPage', { state: { orderId } });
          }
          return;
        } else {
          console.error('Database update failed or returned an unsuccessful response.');
        }
      } catch (error) {
        setPaymentDatabaseUpdateInProgress(false);
        setPaymentSheetInProgress(false);
      }
      return;
    }

    setPaymentSheetInProgress(true);
    try {
      const idToken = await auth.currentUser.getIdToken(true);
      const paymentIntentResponse = await axios.post(
        `${config?.firebaseFunctionEndpoint}/createPaymentIntent`,
        {
          price: totalPrice,
          customerId: stripeCustomerId,
          savedCard: useSavedCard ? savedCard?.id : null,
          instantPayment: false,
          tipPayment: false
        },
        { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${idToken}` } }
      );
      const { clientSecret, status, id } = paymentIntentResponse.data;
      if (!id) {
        alert("Chyba v platbě", "Zkuste to prosím znovu.");
        return;
      }
      setPaymentIntentId(id);
      setClientSecret(clientSecret);

      if (status === 'requires_capture') {
        setPaymentDatabaseUpdateInProgress(true);
        const response = await updateDatabaseAfterPayment(id);
        if (response?.data.success === true) {
          const orderId = response.data.orderId;
          setPaymentDatabaseUpdateInProgress(false);
          setPaymentSheetInProgress(false);
          alert('Objednávka úspěšně zaplacena');
          if (selectedOption === 'now') {
            navigate('/confirmingPage', { state: { orderId } });
          }
        }
        return;
      } else if (status === 'requires_payment_method') {
        const { error } = await stripe.confirmCardPayment(clientSecret, {
          payment_method: {
            card: elements.getElement(CardElement),
            billing_details: {
              email: email,
              name: clientName,
            },
          },
        });
        if (!error) {
          setPaymentDatabaseUpdateInProgress(true);
          const response = await updateDatabaseAfterPayment(id);
          if (response?.data.success === true) {
            const orderId = response.data.orderId;
            setPaymentDatabaseUpdateInProgress(false);
            setPaymentSheetInProgress(false);
            alert('Objednávka úspěšně zaplacena');
            if (selectedOption === 'now') {
              navigate('/confirmingPage', { state: { orderId } });
            }
          }
        } else {
          alert(error.message);
        }
      }
    } catch (error) {
      setPaymentDatabaseUpdateInProgress(false);
      setPaymentSheetInProgress(false);
    } finally {
      setPaymentSheetInProgress(false);
    }
  }
  useEffect(() => {
    const requiredInfo = requiredInfoByTask[selectedServiceTitle];
    const missingInfo = requiredInfo?.filter(param => clientData[param] === null || clientData[param] === undefined);

    if (location && workers && waitingFor && serviceTime && missingInfo.length === 0) {
      setFindingEmployee(true);
      debouncedCallFindEmployeeCloud();
    } else {
      // Handle missing information
      // setAssignedEmployees({});
    }
  }, [location, workers, equipment, pet, waitingFor, selectedServiceTitle, serviceTime]);

  useEffect(() => {
    const clientRef = ref(database, `searchingClients/${currentUserUid}`);
    let lastKnownValue = null;
    let listener = [];
    const handleSnapshot = (snapshot) => {
      const data = snapshot.val();
      if (!data || !data.assignedEmployees || Object.keys(data.assignedEmployees).length === 0) {
        lastKnownValue = null;
        setAssignedEmployees({});
        return;
      }
      const newassignedEmployees = data.assignedEmployees;

      if (JSON.stringify(newassignedEmployees) !== JSON.stringify(lastKnownValue)) {
        setAssignedEmployees(newassignedEmployees);
        lastKnownValue = newassignedEmployees;
      }
    };
    onValue(clientRef, handleSnapshot);
    listener.push({ ref: clientRef, handler: handleSnapshot });
    return () => {
      listener.forEach(({ ref, handler }) => {
        off(ref, 'value', handler);
      });
    };
  }, []);


useEffect(() => {
  let listener = [];
  Object.keys(assignedEmployees).forEach((employeeId) => {
    const employeeRef = ref(database, `availableEmployees/${employeeId}`);
    const handleSnapshot = async (snapshot) => {
      if (!selectedServiceTitle) return;
      const requiredInfo = requiredInfoByTask[selectedServiceTitle];
      const missingInfo = requiredInfo?.filter(param => clientData[param] === null || clientData[param] === undefined); 
      if (missingInfo.length > 0) {
        return;
      }
      const response = await verifyStillMatchingOnEmployeeDatabaseChange(employeeId, currentUserUid, equipment, waitingFor, location);
      if (response?.data?.removed) {
        setTotalPrice('');
        setPriceWithoutTax('');
        setDriveRate('');
        setDiscount('');
        debouncedCallFindEmployeeCloud();
      }
    };
    onValue(employeeRef, handleSnapshot);
    listener.push({ ref: employeeRef, handler: handleSnapshot });
  });

  return () => {
    listener.forEach(({ ref, handler }) => {
      off(ref, 'value', handler);
    });
  };
}, [assignedEmployees, selectedServiceTitle]);


useEffect(() => {
  let listener = [];
  const employeesRef = ref(database, 'availableEmployees/');
  const unsubscribe = (snapshot) => {
    if (Object.keys(assignedEmployees).length === workers) {
      return;
    }
    if (!snapshot.exists()) {
      return;
    }
    snapshot.forEach((childSnapshot) => {
      if (Object.keys(assignedEmployees).length === workers) {
        return;
      }
      const employeeId = childSnapshot.key;
      const employeeData = childSnapshot.val();

      if (workers > 0 && equipment && waitingFor && location?.lat && location?.lon && employeeData) {
        if (
          employeeData.jobs &&
          typeof employeeData.jobs === 'object' &&
          employeeData.jobs[waitingFor] &&
          employeeData.equipment &&
          typeof employeeData.equipment === 'object' &&
          employeeData.equipment[equipment]
        ) {
          debouncedCallFindEmployeeCloud();
        }
      }
    });
  };
  onValue(employeesRef, unsubscribe);
  listener.push({ ref: employeesRef, handler: unsubscribe });

  return () => {
    listener.forEach(({ ref, handler }) => {
      off(ref, 'value', handler);
    });
  };
}, [assignedEmployees, workers, waitingFor, location]);



  useEffect(() => {
    if (assignedEmployees && Object.keys(assignedEmployees).length > 0) {
      const numberOfassignedEmployees = Object.keys(assignedEmployees).length;
      setWorkersFound(numberOfassignedEmployees);
      const employeeArray = Object.values(assignedEmployees);
      if (employeeArray.length > 0) {
        const maxETA = Math.max(...employeeArray.map((emp) => emp.eta));
        const maxETAPlus = maxETA + 10;
        setLongestETA(maxETAPlus);
      }
    } else {
      setWorkersFound(0);
    }
  }, [assignedEmployees]);

  useEffect(() => {
    if (!selectedServiceTitle) return;
    const requiredInfo = requiredInfoByTask[selectedServiceTitle];
    const missingInfo = requiredInfo?.filter(param => clientData[param] === null || clientData[param] === undefined);
    if (missingInfo.length > 0 || !currentUserUid || !serviceTime || !discountFactor || !selectedServiceTitle || !location) {
      return;
    }
    if (selectedOption === 'now' && Object.keys(assignedEmployees).length === 0) {
      setTotalPrice('');
      setPriceWithoutTax('');
      setDriveRate('');
      setDiscount('');
    } else {
      setTotalPrice('');
      setPriceWithoutTax('');
      setDriveRate('');
      setDiscount('');
      debouncedCalculatePrice();
    }

    return () => {
      debouncedCalculatePrice.cancel();
    };
  }, [assignedEmployees, serviceTime, discountFactor, selectedServiceTitle, selectedOption]);

  function parseTime(timeData, homeType) {
    const timeString = typeof timeData === 'string' ? timeData : timeData[homeType];
    if (!timeString) {
      throw new Error(`No time data available for home type: ${homeType}`);
    }
    const [hours, minutes] = timeString.split('h:').map((part) => parseInt(part?.replace('min', ''), 10));
    return { hours, minutes };
  }

  function calculateTime() {
    if (selectedServiceTitle && waitingFor && service) {
      try {
        const { hours, minutes } = parseTime(service.time, homeType);
        let adjustedHours = hours;
        let adjustedMinutes = minutes;

        if (selectedServiceTitle === 'Úklid' || selectedServiceTitle === 'Okna') {
          if (homeSize > 95) {
            const totalMinutes = hours * 60 + minutes;
            let adjustedTotalMinutes = totalMinutes;

            if (homeSize <= 105) {
              const adjustmentFactor = 1 - ((homeSize - 95) * 0.02);
              adjustedTotalMinutes = totalMinutes * adjustmentFactor;
            } else {
              adjustedTotalMinutes = totalMinutes * 0.8;
            }

            adjustedHours = Math.floor(adjustedTotalMinutes / 60);
            adjustedMinutes = Math.round(adjustedTotalMinutes % 60);
          }
        }

        switch (selectedServiceTitle) {
          case 'Úklid':
          case 'Okna':
            if (homeSize && homeSize > 0) {
              return calculateTotalTime(adjustedHours, adjustedMinutes, homeSize);
            }
            break;
          case 'Zahrada':
            if (groundSize && groundSize > 0) {
              return calculateTotalTime(hours, minutes, groundSize);
            }
            break;
          case 'Vysokotlaké čištění':
            if (groundVapeSize && groundVapeSize > 0) {
              return calculateTotalTime(hours, minutes, groundVapeSize);
            }
            break;
          case 'Žehlení':
          case 'Mytí auta':
            return service.time;
          default:
            let size = 0;
            if (homeSize) {
              size = homeSize;
            } else if (groundSize) {
              size = groundSize;
            } else if (groundVapeSize) {
              size = groundVapeSize;
            }
            if (size > 0) {
              return calculateTotalTime(adjustedHours, adjustedMinutes, size);
            }
            break;
        }
      } catch (error) {
        console.error(`Error calculating time: ${error.message}`);
        return;
      }
    }
    return;
  }

  function calculateTotalTime(hours, minutes, size) {
    if (isNaN(hours) || isNaN(minutes) || isNaN(size) || size <= 0) {
      console.error('Invalid inputs for time calculation:', { hours, minutes, size });
      return 'Invalid time data';
    }

    const timePerUnit = (hours * 60 + minutes) / 100;
    const totalTimeMinutes = timePerUnit * size;
    const totalHours = Math.floor(totalTimeMinutes / 60);
    const totalMinutes = totalTimeMinutes % 60;
    return `${totalHours}h:${totalMinutes.toFixed(0)}min`;
  }
  const toMinutes = (time) => {
    if (!time || typeof time !== 'string') {
      console.error('Invalid time input provided:', time);
      return 0;
    }
    const [hourString, minuteString] = time.split(':');
    const hours = parseInt(hourString?.replace('h', ''), 10);
    const minutes = parseInt(minuteString?.replace('min', ''), 10);
    return hours * 60 + minutes;
  };

  const minutesToHours = (minutes) => {
    const h = Math.floor(minutes / 60);
    const min = minutes % 60;
    return `${h}h:${min.toFixed(0)}min`;
  };

  useEffect(() => {
    if (!selectedServiceTitle) return;
    const requiredInfo = requiredInfoByTask[selectedServiceTitle].filter(info => info !== 'inputAddress');
    const missingInfo = requiredInfo?.filter(param => clientData[param] === null || clientData[param] === undefined);
    if (missingInfo.length > 0) {
      setTotalPrice("");
      setServiceTime("");
      return;
    }
    setTotalPrice("");
    setServiceTime("");

    const calculateAndSetWorkers = async () => {
      if (selectedServiceTitle && waitingFor && service) {
        const serviceTime = calculateTime();
        const serviceTimeInMinutes = toMinutes(serviceTime);
        let basicWorkload = serviceTimeInMinutes / 60 / 8;
        const workersNeeded = Math.ceil(basicWorkload);
        setServiceTime(serviceTime);
        setWorkers(workersNeeded);
      }
    };
    const debouncedCalculateAndSetWorkers = debounceAsync(calculateAndSetWorkers, 1000);
    debouncedCalculateAndSetWorkers();

    return () => {
      debouncedCalculateAndSetWorkers.cancel();
    };
  }, [homeSize, homeType, groundSize, groundVapeSize, location, service, selectedServiceTitle, waitingFor, carType, pet, equipment]);

  const getCouponDetails = async (couponCode) => {
    const couponDoc = await getDoc(doc(firestore, 'coupons', 'coupons'));
    if (couponDoc.exists) {
      const couponsData = couponDoc.data();
      const couponDetails = couponsData[couponCode];
      if (couponDetails) {
        return couponDetails;
      }
    }
    return null;
  };

  const handleApplyCoupon = async () => {
    if (!totalPrice) {
      alert('Počkejte, vypočítává se cena');
      return;
    }
    const couponDetails = await getCouponDetails(couponCode);
    if (couponDetails) {
      couponDetails.usedBy = couponDetails.usedBy || [];
      if (couponDetails.usedBy.includes(selectedAddress || inputAddress)) {
        alert('Tento kupon jste již využili.');
      } else if (couponDetails.oneTimeUse && couponDetails.usedBy.length > 0) {
        alert('Tento kupon již byl využit.');
      } else {
        setDiscountFactor(couponDetails.discount);
        setCouponCode(couponCode);
        alert(`Váš kupon byl úspěšně uplatněn! Máte ${((1 - couponDetails.discount) * 100).toFixed(0)}% slevu.`);
      }
    } else {
      alert('Neplatný kupon.');
    }
  };



  // Email validation function
  const validateEmail = (email) => {
    const emailIsValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test((email || '').trim());
    setEmailValid(emailIsValid);
    return emailIsValid;
  };

  // Handle email input change
  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    setEmailInput(emailValue); // Set input state to show the typed email
    validateEmail(emailValue);
  };

  // Apply the email to the main state if valid
  const handleEmailBlur = () => {
    if (emailValid) {
      setEmail(emailInput);
    }
  };

  const handleServiceChange = (service) => {
    setWaitingFor(service.mainTitle);
    updateClientData("waitingFor", service.mainTitle);
  };

  const handleButtonPress = (buttonType) => {
    setHomeType(buttonType);
    updateClientData("homeType", buttonType);
  };

  const handlePetChange = (petValue) => {
    setPet(petValue);
    updateClientData("pet", petValue);
  };

  const handleEquipmentChange = (equipmentValue) => {
    setEquipment(equipmentValue);
    updateClientData("equipment", equipmentValue);
  };

  const handleCarTypeChange = (carTypeValue) => {
    setCarType(carTypeValue);
    updateClientData("carType", carTypeValue);
  };

  const handleHomeSizeChange = (text) => {
    const value = Math.max(0, parseInt(text));
    setHomeSize(value);
    updateClientData("homeSize", value);
  };

  const handleGroundSizeChange = (text) => {
    const value = Math.max(0, parseInt(text));
    setGroundSize(value);
    updateClientData("groundSize", value);
  };

  const handleGroundVapeSizeChange = (text) => {
    const value = Math.max(0, parseInt(text));
    setGroundVapeSize(value);
    updateClientData("groundVapeSize", value);
  };

  useEffect(() => {
    updateClientData("waitingFor", null);
    updateClientData("task", selectedServiceTitle);
    setWaitingFor(null);
    setService("");
    setMissingInfo(null);
  }, [selectedServiceTitle]);

  const getDescription = (size, type) => {
    if (type === 'Byt') {
      if (size <= 40) return 'Malý byt 1+kk-2+kk';
      if (size <= 60) return 'Střední byt 2+kk-3+kk';
      if (size <= 85) return 'Středně velký byt 3+kk-4+kk';
      if (size <= 120) return 'Velký byt 4+kk-5+kk';
      if (size <= 160) return 'Velký byt 5+kk-6+kk';
      if (size <= 200) return 'Velký byt 6+kk-7+kk';
      if (size <= 1000) return 'Obrovský byt 7+kk-10+kk';
    } else if (type === 'Kancelář') {
      if (size <= 30) return 'Malá kancelář';
      if (size <= 300) return 'Velká kancelář';
      if (size <= 3000) return 'Obrovská kancelář';
    } else if (type === 'Rodinný dům') {
      if (size <= 101) return 'Malý dům 2+kk-3+kk';
      if (size <= 200) return 'Středně velký dům 3+kk-4+kk';
      if (size <= 300) return 'Velký dům 4+kk-5+kk';
      if (size <= 400) return 'Extra Velký dům 5+kk-6+kk';
      if (size <= 3000) return 'Mansion 6+kk-12+kk';
    }
    return ''; // Default return value if no conditions are met
  };

  useEffect(() => {
    setDescription(getDescription(parseInt(homeSize, 10), homeType));
  }, [homeSize, homeType]);

  const handleMissingInfoClick = () => {
    const missingFields = requiredInfoByTask[selectedServiceTitle].filter(field => !clientData[field]);
    const missingFieldsText = missingFields.map(field => {
      switch (field) {
        case 'homeSize': return 'velikost domácnosti';
        case 'homeType': return 'typ domácnosti';
        case 'pet': return 'domácí mazlíček';
        case 'equipment': return 'vybavení';
        case 'carType': return 'typ auta';
        case 'groundSize': return 'velikost zahrady';
        case 'groundVapeSize': return 'velikost plochy čištění';
        case 'waitingFor': return 'vybraná služba';
        default: return field;
      }
    }).join(', ');
    setMissingInfo(`Chybí: ${missingFieldsText}`);
  };

  const handleNaPozdejiClick = () => {
    setIsModalOpen(true);
  };
  
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };


  //   const onLogout = () => {
  //   signOut(auth)
  //     .then(() => {
  //       alert('Úspěšné odhlášení');
  //       navigate('/login');
  //     })
  //     .catch((error) => {
  //       alert(error.message);
  //     });
  // };


  return (
    <div style={{ padding: '20px', backgroundColor: 'white', fontFamily: 'Arial, sans-serif', }}>
      <Header />
      {/* <button onClick={onLogout}></button> */}
      <div style={{ display: "flex", flexDirection: is750 ? "column" : "row",}}>
        <div style={{ marginBottom: '20px', width: is750 ? "100%" : "60%" }}>
          <div style={{ display: "flex", flexDirection: is500 ? "column" : "row", alignItems: "center",}}>
            <select
              value={selectedServiceTitle}
              onChange={(e) => setSelectedServiceTitle(e.target.value)}
              style={{
                padding: '15px',
                borderRadius: '8px',
                border: 'none',
                fontSize: '1.25rem',
                backgroundColor: '#f2f5f8',
                color: 'black',
                fontWeight: "bold",
                width: is500 ? "90%" : ""
              }}
            >
              <option value="Úklid" style={{ backgroundColor: '#F2F5F8', color: 'black', padding: '10px' }}>Úklid</option>
              <option value="Žehlení" style={{ backgroundColor: '#F2F5F8', color: 'black', padding: '10px' }}>Žehlení</option>
              <option value="Okna" style={{ backgroundColor: '#F2F5F8', color: 'black', padding: '10px' }}>Okna</option>
              <option value="Mytí auta" style={{ backgroundColor: '#F2F5F8', color: 'black', padding: '10px' }}>Mytí auta</option>
              <option value="Zahrada" style={{ backgroundColor: '#F2F5F8', color: 'black', padding: '10px' }}>Zahrada</option>
              <option value="Vysokotlaké čištění" style={{ backgroundColor: '#F2F5F8', color: 'black', padding: '10px' }}>Vysokotlaké čištění</option>
            </select>
            <div style={{display:"flex", flexDirection:"row", alignItems:"center"}}>
              <img src={clickBlackImg} alt="click icon" style={{ width: 18, height: 18, marginRight: 5, marginLeft: 10 }} />
              <p style={{ fontSize: '0.8rem', color: 'gray' }}>Vyberte si typ služby</p>
            </div>
          </div>
          <div style={{ display: 'flex', flexWrap: 'wrap', marginLeft: -10, alignItems: "center", justifyContent:"center" }}>
          {services.length === 0 ? (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop:50 }}>
                <img src={loadingGif} alt="Loading..." style={{ width: 50, height: 50 }} />
              </div>
            ) : (
              services.map((service, index) => (
                <div
                  key={index}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '20px',
                    margin: '10px',
                    border: waitingFor === service.mainTitle ? '2px solid lightgreen' : '2px solid transparent',
                    borderRadius: '10px',
                    backgroundColor: "#F2F5F8",
                    cursor: 'pointer',
                    width: is500 ? "90%" : 'calc(50% - 30px)',
                    minWidth: is500 ? "" : '300px',
                    height: "auto",
                    boxSizing: 'border-box',
                    transition: 'background-color 0.3s ease',
                    fontSize: '1.1rem',
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                  onClick={() => handleServiceChange(service)}
                >
                  <label className="custom-radio">
                    <input
                      type="radio"
                      checked={waitingFor === service.mainTitle}
                      onChange={() => handleServiceChange(service)}
                    />
                    <span className="checkmark"></span>
                  </label>
                  <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", textAlign: "center" }}>
                    <img src={service.url} alt={service.mainTitle} style={{ width: '50px', height: '50px', marginRight: '10px' }} />
                    <h3 style={{ fontSize: '1.25rem', fontWeight: 'bold', margin: '0 0 15px 0' }}>{service.mainTitle}</h3>
                    <p style={{ fontSize: '1rem', margin: '0', color: '#555' }}>{service.subTitle}</p>
                  </div>
                </div>
              ))
            )}

          </div>
        </div>

        <div style={{ maxWidth: is750 ? "100%" : "40%", width: is750 ? "" : "40%", padding: '20px', backgroundColor: '#F2F5F8', borderRadius: '8px', }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px', }}>
            <button style={{
              flex: 1,
              backgroundColor: selectedOption === 'now' ? '#00da00' : '#ccc',
              color: 'white',
              padding: '10px 0',
              border: 'none',
              borderRadius: '5px 0 0 5px',
              fontWeight: 'bold',
              cursor: 'pointer',
              fontSize: 16
            }}
              onClick={() => setSelectedOption('now')}
            >
              Standardní
            </button>
            <button
              style={{
                flex: 1,
                backgroundColor: selectedOption === 'later' ? '#00da00' : '#ccc',
                color: 'white',
                padding: '10px 0',
                border: 'none',
                borderRadius: '0 5px 5px 0',
                fontWeight: 'bold',
                cursor: 'pointer',
                fontSize: 16
              }}
              onClick={handleNaPozdejiClick}
            >
              Na později
            </button>
          </div>
          <div style={{ marginBottom: '20px', display: "flex", flexDirection: "column" }}>
            <label style={{
              fontWeight: 'bold',
              fontSize: 16,
              color: '#333',
            }}>Email</label>
            <div style={{
              position: "relative",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",

            }}>
              <img src={emailImg} alt="location icon" style={{
                position: 'absolute',
                left: 10,
                width: 20,
                height: 20,
                marginTop: 6
              }} />
              <input
                type="email"
                placeholder="jan@tasker.cz"
                style={{
                  width: '100%',
                  height: 50,
                  paddingLeft: 40,
                  borderRadius: '5px',
                  border: emailValid ? 'none' : '2px solid red', // Red border if invalid
                  fontSize: '1rem',
                  marginTop: 5,
                }}
                value={emailInput}
                onChange={handleEmailChange}
                onBlur={handleEmailBlur} // Set email state on blur if valid
              />
            </div>
          </div>
          <div style={{ marginBottom: '20px' }}>
            <label style={{
              fontWeight: 'bold',
              fontSize: 16,
              color: '#333',
              marginTop: 20,
            }}>Adresa</label>
            <div style={{
              position: "relative",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              marginTop: 5
            }}>
              <img src={locationImg} alt="location icon" style={{
                position: 'absolute',
                left: 10,
                width: 20,
                height: 20,
              }} />
              <input
                id="autocomplete-address"
                type="text"
                placeholder="Zadejte adresu a číslo popisné"
                style={{
                  width: '100%',
                  height: 50,
                  paddingLeft: 40,
                  borderRadius: 10,
                  backgroundColor: 'white',
                  color: '#333',
                  border: 'none',
                  fontSize: 16,
                }}
              />
            </div>
          </div>

          {(selectedServiceTitle === 'Úklid' || selectedServiceTitle === 'Okna') && (
            <div style={{ marginTop: 20 }}>
              <label style={{
                fontWeight: 'bold',
                fontSize: 16,
                color: '#333',
                marginTop: 20,
                marginBottom: 5,
              }}>Velikost domácnosti v m²</label>
              <p style={{
                fontWeight: 'bold',
                fontSize: 14,
                color: '#8b9cb5',
                marginTop: 5,
                marginBottom: 10,
              }}>
                {description || 'Odhad'}
              </p>
              <div style={{
                position: 'relative',
                marginBottom: 20,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center"
              }}>
                <img src={sizeImg} alt="size icon" style={{
                  position: 'absolute',
                  left: 10,
                  top: '50%',
                  transform: 'translateY(-50%)',
                  width: 20,
                  height: 20,
                }} />
                <input
                  style={{
                    width: '100%',
                    height: 50,
                    paddingLeft: 40,
                    paddingRight: 40,
                    borderRadius: 10,
                    backgroundColor: 'white',
                    color: '#333',
                    border: 'none',
                    fontSize: 16,
                  }}
                  placeholder="Zadejte velikost domu"
                  onChange={(e) => handleHomeSizeChange(e.target.value)}
                  value={homeSize}
                  type="number"
                  min="0"
                />
                <span style={{
                  position: 'absolute',
                  right: 15,
                  top: '50%',
                  transform: 'translateY(-50%)',
                  color: '#8b9cb5',
                }}>m²</span>
              </div>
            </div>
          )}

          {(selectedServiceTitle === 'Úklid' || selectedServiceTitle === 'Okna') && (
            <>
              <div style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: 20,
              }}>
                <label style={{
                  fontWeight: 'bold',
                  fontSize: 16,
                  color: '#333',
                  marginRight: 10,
                }}>Typ domácnosti</label>
                <img src={clickBlackImg} alt="click icon" style={{
                  width: 20,
                  height: 20,
                }} />
              </div>
              <div style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: 10,
              }}>
                <button
                  style={{
                    flex: 1,
                    padding: '12px 0',
                    borderWidth: homeType === 'Byt' ? 1 : 0,
                    borderColor: homeType === 'Byt' ? '#2ab043' : '#8b9cb5',
                    backgroundColor: homeType === 'Byt' ? '#8dff8d' : 'white',
                    borderRadius: homeType === 'Byt' ? 10 : 0,
                    cursor: "pointer"
                  }}
                  onClick={() => handleButtonPress('Byt')}
                >
                  Byt
                </button>
                <button
                  style={{
                    flex: 1,
                    padding: '12px 0',
                    borderWidth: homeType === 'Rodinný dům' ? 1 : 0,
                    borderColor: homeType === 'Rodinný dům' ? '#2ab043' : '#8b9cb5',
                    backgroundColor: homeType === 'Rodinný dům' ? '#8dff8d' : 'white',
                    borderRadius: homeType === 'Rodinný dům' ? 10 : 0,
                    cursor: "pointer"
                  }}
                  onClick={() => handleButtonPress('Rodinný dům')}
                >
                  Rodinný dům
                </button>
                <button
                  style={{
                    flex: 1,
                    padding: '12px 0',
                    borderWidth: homeType === 'Kancelář' ? 1 : 0,
                    borderColor: homeType === 'Kancelář' ? '#2ab043' : '#8b9cb5',
                    backgroundColor: homeType === 'Kancelář' ? '#8dff8d' : 'white',
                    borderRadius: homeType === 'Kancelář' ? 10 : 0,
                    cursor: "pointer"
                  }}
                  onClick={() => handleButtonPress('Kancelář')}
                >
                  Kancelář
                </button>
              </div>
            </>
          )}

          {selectedServiceTitle === 'Úklid' && (
            <>
              <div style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: 20,
              }}>
                <label style={{
                  fontWeight: 'bold',
                  fontSize: 16,
                  color: '#333',
                  marginRight: 10,
                }}>Máte domácí mazlíčky?</label>
                <img src={clickBlackImg} alt="click icon" style={{
                  width: 20,
                  height: 20,
                }} />
              </div>
              <div style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: 10,
              }}>
                <button
                  style={{
                    flex: 1,
                    padding: '12px 0',
                    borderWidth: pet === 'Ano' ? 1 : 0,
                    borderColor: pet === 'Ano' ? '#2ab043' : '#8b9cb5',
                    backgroundColor: pet === 'Ano' ? '#8dff8d' : 'white',
                    borderRadius: pet === 'Ano' ? 10 : 0,
                    cursor: "pointer"
                  }}
                  onClick={() => handlePetChange('Ano')}
                >
                  Ano
                </button>
                <button
                  style={{
                    flex: 1,
                    padding: '12px 0',
                    borderWidth: pet === 'Ne' ? 1 : 0,
                    borderColor: pet === 'Ne' ? '#2ab043' : '#8b9cb5',
                    backgroundColor: pet === 'Ne' ? '#8dff8d' : 'white',
                    borderRadius: pet === 'Ne' ? 10 : 0,
                    cursor: "pointer"
                  }}
                  onClick={() => handlePetChange('Ne')}
                >
                  Ne
                </button>
              </div>

              <div style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: 20,
              }}>
                <label style={{
                  fontWeight: 'bold',
                  fontSize: 16,
                  color: '#333',
                  marginRight: 10,
                }}>Máte mop i vysavač?</label>
                <img src={clickBlackImg} alt="click icon" style={{
                  width: 20,
                  height: 20,
                }} />
              </div>
              <div style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: 10,
              }}>
                <button
                  style={{
                    flex: 1,
                    padding: '12px 0',
                    borderWidth: equipment === 'Vybavení klienta' ? 1 : 0,
                    borderColor: equipment === 'Vybavení klienta' ? '#2ab043' : '#8b9cb5',
                    backgroundColor: equipment === 'Vybavení klienta' ? '#8dff8d' : 'white',
                    borderRadius: equipment === 'Vybavení klienta' ? 10 : 0,
                    cursor: "pointer"
                  }}
                  onClick={() => handleEquipmentChange('Vybavení klienta')}
                >
                  Ano
                </button>
                <button
                  style={{
                    flex: 1,
                    padding: '12px 0',
                    borderWidth: equipment === 'Vybavení pracovníka' ? 1 : 0,
                    borderColor: equipment === 'Vybavení pracovníka' ? '#2ab043' : '#8b9cb5',
                    backgroundColor: equipment === 'Vybavení pracovníka' ? '#8dff8d' : 'white',
                    borderRadius: equipment === 'Vybavení pracovníka' ? 10 : 0,
                    cursor: "pointer"
                  }}
                  onClick={() => handleEquipmentChange('Vybavení pracovníka')}
                >
                  Ne
                </button>
              </div>
            </>
          )}

          {selectedServiceTitle === 'Mytí auta' && (
            <>
              <div style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: 20,
              }}>
                <label style={{
                  fontWeight: 'bold',
                  fontSize: 16,
                  color: '#333',
                  marginRight: 10,
                }}>Typ auta</label>
                <img src={clickBlackImg} alt="click icon" style={{
                  width: 20,
                  height: 20,
                }} />
              </div>
              <div style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: 10,
              }}>
                <button
                  style={{
                    flex: 1,
                    padding: '12px 0',
                    borderWidth: carType === 'Sedan' ? 1 : 0,
                    borderColor: carType === 'Sedan' ? '#2ab043' : '#8b9cb5',
                    backgroundColor: carType === 'Sedan' ? '#8dff8d' : 'white',
                    borderRadius: carType === 'Sedan' ? 10 : 0,
                    cursor: "pointer"
                  }}
                  onClick={() => handleCarTypeChange('Sedan')}
                >
                  Sedan
                </button>
                <button
                  style={{
                    flex: 1,
                    padding: '12px 0',
                    borderWidth: carType === 'SUV' ? 1 : 0,
                    borderColor: carType === 'SUV' ? '#2ab043' : '#8b9cb5',
                    backgroundColor: carType === 'SUV' ? '#8dff8d' : 'white',
                    borderRadius: carType === 'SUV' ? 10 : 0,
                    cursor: "pointer"
                  }}
                  onClick={() => handleCarTypeChange('SUV')}
                >
                  SUV
                </button>
              </div>
            </>
          )}

          {selectedServiceTitle === 'Zahrada' && (
            <>
              <div style={{ marginTop: 20 }}>
                <label style={{
                  fontWeight: 'bold',
                  fontSize: 16,
                  color: '#333',
                  marginTop: 20,
                  marginBottom: 5,
                }}>Velikost zahrady v m²</label>
                <div style={{
                  position: 'relative',
                  marginBottom: 20,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 5
                }}>
                  <img src={sizeImg} alt="size icon" style={{
                    position: 'absolute',
                    left: 10,
                    top: '50%',
                    transform: 'translateY(-50%)',
                    width: 20,
                    height: 20,
                  }} />
                  <input
                    style={{
                      width: '100%',
                      height: 50,
                      paddingLeft: 40,
                      paddingRight: 40,
                      borderRadius: 10,
                      backgroundColor: 'white',
                      color: '#333',
                      border: 'none',
                      fontSize: 16,
                    }}
                    placeholder="Zadejte velikost zahrady"
                    onChange={(e) => handleGroundSizeChange(e.target.value)}
                    value={groundSize}
                    type="number"
                    min="0"
                  />
                  <span style={{
                    position: 'absolute',
                    right: 15,
                    top: '50%',
                    transform: 'translateY(-50%)',
                    color: '#8b9cb5',
                  }}>m²</span>
                </div>
              </div>
            </>
          )}

          {selectedServiceTitle === 'Vysokotlaké čištění' && (
            <>
              <div style={{ marginTop: 20 }}>
                <label style={{
                  fontWeight: 'bold',
                  fontSize: 16,
                  color: '#333',
                  marginTop: 20,
                  marginBottom: 5,
                }}>Velikost plochy čištění v m²</label>
                <div style={{
                  position: 'relative',
                  marginBottom: 20,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 5
                }}>
                  <img src={sizeImg} alt="size icon" style={{
                    position: 'absolute',
                    left: 10,
                    top: '50%',
                    transform: 'translateY(-50%)',
                    width: 20,
                    height: 20,
                  }} />
                  <input
                    style={{
                      width: '100%',
                      height: 50,
                      paddingLeft: 40,
                      paddingRight: 40,
                      borderRadius: 10,
                      backgroundColor: 'white',
                      color: '#333',
                      border: 'none',
                      fontSize: 16,
                    }}
                    placeholder="Zadejte velikost plochy"
                    onChange={(e) => handleGroundVapeSizeChange(e.target.value)}
                    value={groundVapeSize}
                    type="number"
                    min="0"
                  />
                  <span style={{
                    position: 'absolute',
                    right: 15,
                    top: '50%',
                    transform: 'translateY(-50%)',
                    color: '#8b9cb5',
                  }}>m²</span>
                </div>
              </div>
            </>
          )}
          <Modal isOpen={isModalOpen} onClose={handleCloseModal} />
          <div style={{ width: '100%', display: "flex", flexDirection: "column", alignItems: "center", alignSelf: 'center', marginTop: 20, paddingVertical: 8, backgroundColor: '#F2F5F8' }}>
            {(inputAddress || selectedAddress) && clientData && requiredInfoByTask[selectedServiceTitle].every((param) => clientData[param] !== null && clientData[param] !== undefined) ? (
              <div style={{ color: 'black', width: '100%', display: 'flex', justifyContent: 'center' }}>
                {workersFound && selectedOption === 'now' && !findingEmployee && Object.keys(assignedEmployees).length > 0 ? (
                  <div style={{position:"relative", backgroundColor:"#8dff8d", borderRadius:10, fontSize: is500 ? "0.7rem" : "1rem", paddingTop: 5, paddingBottom: 5, paddingLeft: 15, paddingRight: 15, display:"flex", alignItems:"center", justifyContent: 'center' }}>
                    Pracovník dorazí do
                    <span style={{ textDecoration: 'underline', color: '#2ab043', marginLeft:5 }}>{longestETA !== undefined ? ' ' + longestETA + ' min' : ' Výpočet...'}</span>
                    <img src={sipkaIkonkaKruhImg} alt="icon" style={{ width: 15, height: 15, marginLeft: 5 }} />
                  </div>
                ) : (
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    Hledá se pracovník
                    <img src={loadingGif} alt="icon" style={{ width: 15, height: 15, marginLeft: 5 }} />
                  </div>
                )}
              </div>
            ) : (inputAddress || selectedAddress) ? (
              <div style={{ flexDirection: 'row', textAlign: 'center' }}>
                <span style={{ color: 'black' }}>Pro výpočet ceny </span>
                <a onClick={handleMissingInfoClick} style={{ textDecoration: 'underline', cursor: 'pointer' }}>doplňte informace</a>
                <div style={{ color: 'red', marginTop: '10px' }}>{missingInfo}</div>
              </div>
            ) : (
              <div style={{ flexDirection: 'row', textAlign: 'center' }}>
                <span style={{ color: 'black' }}>Pro výpočet ceny </span>
                <a onClick={() => alert('Zadejte adresu')} style={{ textDecoration: 'underline', cursor: 'pointer' }}>zadejte adresu</a>
              </div>
            )}
          </div>

          <div style={{ marginTop: 10, width:"100%", display:"flex", justifyContent:"center" }}>
            <span>Délka služby: {serviceTime ? serviceTime && workersFound > 1 ? minutesToHours(toMinutes(serviceTime) / workersFound) : serviceTime : "Neznámá"}</span>
          </div>

          <div style={{ marginBottom: '10px', display: "flex", flexDirection: "column", marginTop: 20 }}>
            <label style={{ fontSize: '0.8rem', color: 'gray', marginBottom: 5 }}>Máte slevový voucher?</label>
            <input
              type="text"
              placeholder="Váš kupón"
              value={couponCode}
              onChange={(e) => setCouponCode(e.target.value)}
              style={{
                maxWidth: '100%',
                padding: '10px',
                borderRadius: '5px',
                border: '1px solid #ccc',
                marginBottom: '10px',
                fontSize: '1rem',
              }}
            />
            <button
              style={{
                backgroundColor: '#00da00',
                color: 'white',
                padding: '10px 15px',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
                marginBottom: '0px',
                fontWeight: 'bold',
                width: '100%'
              }}
              onClick={handleApplyCoupon}
            >
              Uplatnit kupón
            </button>
          </div>

          {totalPrice && workersFound >0 && 
          <div style={{ marginTop:40 }}>
          <CardElement
            options={{
              hidePostalCode: true,
              style: {
                base: {
                  fontSize: is500 ? "12px" : '18px',
                  color: 'green',
                  '::placeholder': {
                    color: '#ahb7c4',
                  },
                },
                invalid: {
                  color: 'red',
                },
              },
            }}
          />
        </div>
        }

        <div style={{ display: "flex", flexDirection:"row", alignItems:"center", width:"100%", justifyContent:"space-between", marginBottom: 10, marginTop:40}}>
          <span style={{ fontSize: is500 ? "0.7rem" : '1.2rem', color: 'black', marginRight:10, fontWeight:"bold" }}>Celková cena <span style={{fontSize: is500 ? "0.4rem" : '0.8rem', fontWeight:"light", color:"rgb(139, 156, 181)"}}>(vč. dopravy)</span></span>
          <span style={{ fontSize: is500 ? "0.8rem" : '1.2rem', fontWeight: 'bold', }}>{calculatingPrice ? 'probíhá výpočet ceny' : totalPrice ? totalPrice + ' Kč' : 'Neznámá'}</span>
        </div>


      
      
        <div style={{width:"100%", display:"flex", justifyContent:"center"}}>
          <button
            style={{
              alignSelf: 'center',
              alignItems: 'center',
              justifyContent: 'center',
              paddingTop: 12,
              paddingBottom: 12,
              marginTop: 0,
              width: '100%',
              backgroundColor:
                (inputAddress || selectedAddress) && clientData && requiredInfoByTask[selectedServiceTitle].every((param) => clientData[param] !== null && clientData[param] !== undefined) && (selectedOption === 'later' || Object.keys(assignedEmployees).length > 0)
                  ? '#00da00'
                  : '#cccccc',
              borderRadius: 10,
              flexDirection: 'row',
              display: 'flex',
              cursor: 'pointer',
              border: 'none',
            }}
            onClick={() => {
              const requiredInfo = requiredInfoByTask[selectedServiceTitle];
              const missingInfo = requiredInfo.filter((param) => clientData[param] === null || clientData[param] === undefined);
              if (missingInfo.length > 0) {
                handleMissingInfoClick()
              } else if (selectedOption === 'now' && Object.keys(assignedEmployees).length === 0) {
                alert('Počkejte, vyhledávají se pracovníci');
              } else if (!totalPrice) {
                alert('Počkejte, vypočítává se cena');
              } else {
                handlePayment();
              }
            }}
          >
            <span style={{ color: 'white', fontSize: 20, fontWeight: 'bold', marginLeft: 15, fontSize: is500 ? "0.8rem" : "1rem" }}>
              {(inputAddress || selectedAddress) && clientData && requiredInfoByTask[selectedServiceTitle].every((param) => clientData[param] !== null && clientData[param] !== undefined)
                ? 'OBJEDNAT NYNÍ'
                : 'DOPLŇTE INFORMACE'}
            </span>
            <img src={paymentSheetInProgress ? loadingGif : clickImg} alt="loading icon" style={{ width: 35, height: 35, marginLeft: 15 }} />
          </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const CheckoutPageWrapper = () => {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutPage />
    </Elements>
  );
};

export default CheckoutPageWrapper;
