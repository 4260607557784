import React, { useEffect, useState } from 'react';
import taskerLogo from '../images/Tsk1.png';
import messengerIcon from '../images/messengerikonka.png';
import whatsappIcon from '../images/whatsappikonka.png';

const Header = () => {
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 750);

  const updateMedia = () => {
    setIsWideScreen(window.innerWidth > 750);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  }, []);

  return (
    <header style={{ 
      position: 'relative', 
      width: '95%', 
      display: 'flex', 
      justifyContent: 'space-between', 
      alignItems: 'center', 
      left: '50%', 
      transform: 'translate(-50%)', 
      marginTop: '20px',
      marginBottom: '50px'
    }}>
      <div style={{ position: 'relative' }}>
        <img src={taskerLogo} alt="Tasker" style={{ width: isWideScreen ? '150px' : '80px' }} />
      </div>
      <div style={{ 
        position: 'relative', 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center', 
        gap: '10px',
        left: isWideScreen ? 0 : 20 
      }}>
        <span style={{ fontSize: '12px', fontWeight: 'bold' }}>+420 608 708 962</span>
        <a href="https://m.me/taskercz?text=Dobrý den, potřebuji poradit s aplikací." target="_blank">
          <img src={messengerIcon} alt="Messenger" style={{ width: '30px', cursor: 'pointer' }} />
        </a>
        <a href="https://wa.me/420608708962" target="_blank">
          <img src={whatsappIcon} alt="WhatsApp" style={{ width: '30px', marginRight: '10px', cursor: 'pointer' }} />
        </a>
      </div>
    </header>
  );
};

export default Header;
