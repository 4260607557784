  import React, { useState, useEffect, useRef, useContext} from 'react';
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail, signInWithPhoneNumber, RecaptchaVerifier, PhoneAuthProvider, signInWithCredential } from 'firebase/auth';
import { getFirestore, getDoc, doc } from 'firebase/firestore';
import { UserContext } from '../UserContext'; // Import the UserContext
import { getAnalytics } from 'firebase/analytics';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import CountryFlag from 'react-country-flag';
import config from '../config';
import loadingImg from '../images/loadingImage.gif';
import emailImg from '../images/Iconemail.png';
import passwordImg from '../images/Iconzamek.png';
import nextImg from '../images/ikonkaNext.png';
import countryData from '../countries.json';

// Initialize Firebase
const firebaseConfig = {
  apiKey: config.firebase.apiKey,
  authDomain: config.firebase.authDomain,
  projectId: config.firebase.projectId,
  storageBucket: config.firebase.storageBucket,
  messagingSenderId: config.firebase.messagingSenderId,
  appId: config.firebase.appId,
  databaseURL: config.firebase.databaseURL,
  measurementId: config.firebase.measurementId,
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const analytics = getAnalytics(app);
const firestore = getFirestore(app);


const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { requiresRecentLogin = false, page = '' } = location.state || {};
  const { setRegistrationComplete } = useContext(UserContext); // Use the context
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [pressed, setPressed] = useState(false);
  const [pressed2, setPressed2] = useState(false);
  const [pressedEmail, setPressedEmail] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [switchToEmail, setSwitchToEmail] = useState(false);
  const [areas, setAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const recaptchaContainerRef = useRef(null);
  const [recaptchaVerifier, setRecaptchaVerifier] = useState(null);
  const [is500, setIs500] = useState(window.innerWidth < 500);
  const [is750, setIs750] = useState(window.innerWidth < 750);
  const [is1000, setIs1000] = useState(window.innerWidth < 1000);
  const updateMedia = () => {
    setIs1000(window.innerWidth < 1000);
    setIs750(window.innerWidth < 750);
    setIs500(window.innerWidth < 500);
  };
  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  }, []);


  useEffect(() => {
    const areaData = countryData.map(item => ({
      code: item.alpha2Code,
      name: item.name,
      callingCode: `+${item.callingCodes[0]}`,
    }));
    setAreas(areaData);
    const defaultData = areaData.find(a => a.code === 'CZ') || areaData[0];
    setSelectedArea(defaultData);

    // Initialize reCAPTCHA verifier
    const timer = setTimeout(() => {
      if (!recaptchaVerifier && recaptchaContainerRef.current) {
        const verifier = new RecaptchaVerifier(auth, recaptchaContainerRef.current, {
          size: 'invisible',
          callback: () => {
          },
        });
        setRecaptchaVerifier(verifier);
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [recaptchaVerifier]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user && !requiresRecentLogin) {
        setEmail('');
        setPassword('');
        navigate('/home');
      }
    });
    return () => unsubscribe();
  }, [navigate, requiresRecentLogin]);

  const handleEmailLogin = async () => {
    setPressedEmail(true);
    if (!email.trim() || !password.trim()) {
      alert('Zadejte email a heslo');
      return;
    }
    try {
      await signInWithEmailAndPassword(auth, email, password);
      alert('Úspěšné přihlášení');
      setRegistrationComplete(true); // Set registration complete
      navigate('/home'); // Navigate to home directly
    } catch (error) {
      alert('Chybné údaje', error.message);
    } finally {
      setPressedEmail(false);
    }
  };

  const handlePhoneLogin = async () => {
    setPressed(true);
    if (!phoneNumber.trim()) {
      setPressed(false);
      alert('Zadejte tel. číslo');
      return;
    }
    const phoneNumberWithCode = selectedArea?.callingCode + phoneNumber;
    try {
      const response = await fetch(`${config.firebaseFunctionEndpoint}/checkPhoneNumberRegistration`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ phoneNumber: phoneNumberWithCode }),
      });
      const { isRegistered } = await response.json();
      if (isRegistered) {
        const confirmationResult = await signInWithPhoneNumber(auth, phoneNumberWithCode, recaptchaVerifier);
        setConfirmationResult(confirmationResult);
        setShowOTP(true);
        setPhoneNumber('');
      } else {
        alert('Toto číslo není registrováno');
      }
    } catch (error) {
      alert('Error', error.message);
    } finally {
      setPressed(false);
    }
  };

  const handleVerification = async () => {
    setPressed2(true);
    if (!verificationCode.trim()) {
      setPressed2(false);
      alert('Zadejte verifikační kód');
      return;
    }
    try {
      const credential = PhoneAuthProvider.credential(confirmationResult.verificationId, verificationCode);
      await signInWithCredential(auth, credential);
      alert('Úspěch, Jste přihlášeni!');
      setRegistrationComplete(true); // Set registration complete
      navigate('/home'); // Navigate to home directly
    } catch (error) {
      alert('Error', error.message);
    } finally {
      setPressed2(false);
    }
  };

  const handleResetPassword = async () => {
    if (!email.trim()) {
      alert('Zadejte prosím platný email');
      return;
    }
    try {
      await sendPasswordResetEmail(auth, email);
      alert('Odkaz pro změnu hesla jsme Vám zaslali na daný email.');
      setShowForm(false);
    } catch (error) {
      alert('Error', error.message);
    }
  };

  const handlePhoneNumberChange = data => {
    const numericData = data.replace(/[^0-9]/g, '');
    setPhoneNumber(numericData);
  };

  const handleVerificationCodeChange = text => {
    const numericText = text.replace(/[^0-9]/g, '');
    setVerificationCode(numericText);
  };

  if (!selectedArea) {
    return (
      <div style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        <img src={loadingImg} alt="loading" style={{ width: 100, height: 100 }} />
      </div>
    );
  }

  return (
    <div style={{ flex: 1, backgroundColor: 'white' }}>
      <div style={{ marginTop: 100, textAlign: 'center' }}>
        {switchToEmail ? (
          <div>
            <h1 style={{ color: 'black', fontWeight: 'bold', fontSize: 34 }}>Přihlášení emailem</h1>
            <p style={{ color: 'black', fontSize: 16, marginTop: 10 }}>Zadejte svůj email a heslo.</p>
            <div style={{ marginTop: 40 }}>
              <div style={{ width: '100%', backgroundColor: '#f2f5f8', borderRadius: 8, display: 'flex', alignItems: 'center', padding: 10 }}>
                <img src={emailImg} alt="email" style={{ width: 35, height: 35, marginRight: 10 }} />
                <input
                  type="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  placeholder="Email"
                  style={{ height: 60, paddingLeft: 10, width: '100%', border: 'none', outline: 'none', backgroundColor: '#f2f5f8', fontSize: 16 }}
                />
              </div>
              <div style={{ width: '100%', backgroundColor: '#f2f5f8', borderRadius: 8, display: 'flex', alignItems: 'center', padding: 10, marginTop: 20 }}>
                <img src={passwordImg} alt="password" style={{ width: 35, height: 35, marginRight: 10 }} />
                <input
                  type="password"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  placeholder="Heslo"
                  style={{ height: 60, paddingLeft: 10, width: '100%', border: 'none', outline: 'none', backgroundColor: '#f2f5f8', fontSize: 16 }}
                />
              </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                <span style={{ fontSize: '16px', color: '#666' }}>Nemáte účet?</span>
                <Link to="/register" style={{ fontSize: '16px', color: '#2ab043', marginLeft: '5px', textDecoration: 'none' }}>Registrovat se</Link>
            </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 20 }}>
                <button onClick={() => setShowForm(true)} style={{ backgroundColor: 'transparent', border: 'none', color: '#2ab043', fontSize: 18 }}>Nevíte heslo?</button>
                <button onClick={handleEmailLogin} style={{ backgroundColor: '#00da00', color: 'white', padding: '10px 20px', borderRadius: 8, fontSize: 17, fontWeight: 'bold', cursor: 'pointer' }}>
                  {pressedEmail ? 'Načítání...' : 'PŘIHLÁSIT SE'}
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div>
            {showOTP ? (
              <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
                <h1 style={{ color: 'black', fontWeight: 'bold', fontSize: 34 }}>Odeslali jsme kód</h1>
                <p style={{ color: 'black', fontSize: 16, marginTop: 10 }}>Zadejte ověřovací kód poslaný na zadané číslo.</p>
                <div style={{ marginTop: 40, maxWidth:"500px", }}>
                  <input
                    type="text"
                    value={verificationCode}
                    onChange={e => handleVerificationCodeChange(e.target.value)}
                    placeholder="Zadejte verifikační kód"
                    style={{ height: 60, paddingLeft: 15, width: '90%', border:"none", borderRadius: 8, backgroundColor: '#f2f5f8', fontSize: 22, marginBottom: 20 }}
                  />
                  <button onClick={handleVerification} style={{ backgroundColor: '#00da00', border:"none", color: 'white', padding: '10px 20px', borderRadius: 8, fontSize: 17, fontWeight: 'bold', cursor: 'pointer' }}>
                    {pressed2 ? 'Načítání...' : 'OVĚŘIT'}
                  </button>
                </div>
              </div>
            ) : (
              <div>
                <h1 style={{ color: 'black', fontWeight: 'bold', fontSize: 34 }}>Přihlášení tel. číslem</h1>
                <p style={{ color: 'black', fontSize: 16, marginTop: 10 }}>Zadejte své telefonní číslo. Pošleme vám tam potvrzovací kód.</p>
                <div style={{ marginTop: 40, width:"100%", maxWidth:"600px", margin: "0 auto", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center" }}>
                  <div style={{ display: 'flex', alignItems: 'center', gap: '10px', maxWidth: '100%', width:"90%", alignSelf: 'center', borderRadius: 5 }}>
                    <div style={{ width: '30%', backgroundColor: '#f2f5f8', borderRadius: 8, display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', height: '60px' }} onClick={() => setModalVisible(true)}>
                      {selectedArea && (
                        <>
                          <CountryFlag countryCode={selectedArea.code.toLowerCase()} svg style={{ width: is500 ? 15 : 25, height: 15 }} />
                          <span style={{ color: 'black', marginLeft: 5, fontSize: is500 ? 12 : 16 }}>{selectedArea.callingCode}</span>
                        </>
                      )}
                    </div>
                    <input
                      type="text"
                      value={phoneNumber}
                      onChange={e => handlePhoneNumberChange(e.target.value)}
                      placeholder="Telefonní číslo"
                      style={{ width: '70%', backgroundColor: '#f2f5f8', borderRadius: 8, height: 60, paddingLeft: 15, fontSize: 16, border: 'none', outline: 'none' }}
                    />
                  </div>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                  <span style={{ fontSize: '16px', color: '#666' }}>Nemáte účet?</span>
                    <Link to="/register" style={{ fontSize: '16px', color: '#2ab043', marginLeft: '5px', textDecoration: 'none' }}>Registrovat se</Link>
                </div>
                  <button onClick={handlePhoneLogin} style={{ backgroundColor: '#00da00', color: 'white', border:"none", padding: '20px 50px', borderRadius: 8, fontSize: 17, fontWeight: 'bold', cursor: 'pointer', marginTop: 20 }}>
                    {pressed ? 'Načítání...' : 'PŘIHLÁSIT SE'}
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      {modalVisible && (
        <div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0,0,0,0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }}>
          <div style={{ backgroundColor: '#fff', borderRadius: 15, padding: 30, maxHeight: '80%', overflowY: 'auto', position: 'relative', width: '90%', maxWidth: '400px' }}>
            <button onClick={() => setModalVisible(false)} style={{ position: 'absolute', top: 10, right: 10, backgroundColor: 'transparent', border: 'none', fontSize: 24, cursor: 'pointer', color: '#666' }}>&times;</button>
            {areas.map(area => (
              <div key={area.code} style={{ display: 'flex', alignItems: 'center', padding: 15, cursor: 'pointer', borderBottom: '1px solid #f0f0f0', transition: 'background-color 0.2s' }} onClick={() => { setSelectedArea(area); setModalVisible(false); }}>
                <CountryFlag countryCode={area.code.toLowerCase()} svg style={{ width: 25, height: 20 }} />
                <span style={{ marginLeft: 15, fontSize: 16 }}>{area.name}</span>
                <span style={{ marginLeft: 'auto', color: '#666' }}>{area.callingCode}</span>
              </div>
            ))}
          </div>
        </div>
      )}

      <div ref={recaptchaContainerRef} id="recaptcha-container"></div>
    </div>
  );
}

export default Login;
