// config.js

// const env = process.env.NODE_ENV || 'development';
const env = "production";

let config;

if (env === 'development') {
    config = require('./env.dev.json');
} else if (env === 'production') {
    config = require('./env.prod.json');
} else {
    // Default or other environments
    config = require('./env.prod.json');    // Adjust as needed
}

export default config;