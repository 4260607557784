import React, { createContext, useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

const UserContext = createContext();

const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [registrationComplete, setRegistrationComplete] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    const firestore = getFirestore();

    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);

        // Check if registration is complete
        const userDoc = await getDoc(doc(firestore, 'users', currentUser.uid));
        if (userDoc.exists() && (userDoc.data().registrationComplete || (userDoc.data().name && userDoc.data().email && userDoc.data().phoneNumber))) {
          setRegistrationComplete(true);
        } else {
          setRegistrationComplete(false);
        }
      } else {
        setUser(null);
        setRegistrationComplete(false);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const completeRegistration = () => {
    setRegistrationComplete(true);
  };

  return (
    <UserContext.Provider value={{ user, loading, registrationComplete, setRegistrationComplete: completeRegistration }}>
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
