import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { getDatabase, ref, onValue, off } from 'firebase/database';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import axios from 'axios';
import loadingImg from '../images/loadingImage.gif';
import tickGreen from '../images/pluscheck.png';
import employeePhoto from '../images/workercekani.png';
import xImg from "../images/Xkopopup.png";
import config from '../config'; // Import the config variable

const ConfirmingPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const auth = getAuth();
  const database = getDatabase();
  const firestore = getFirestore();

  const currentUserUid = auth.currentUser?.uid;
  const { orderId } = location.state;
  const [pressed, setPressed] = useState(false);
  const [assignedEmployees, setAssignedEmployees] = useState({});
  const [workers, setWorkers] = useState(0);
  const [countdown, setCountdown] = useState(0);
  const [showRefundModal, setShowRefundModal] = useState(false);
  const [showPartialAcceptModal, setShowPartialAcceptModal] = useState(false);
  const [orderStartTime, setOrderStartTime] = useState(null);
  const [confirmedEmployeesCount, setConfirmedEmployeesCount] = useState(0);
  const [updatedServiceTime, setUpdatedServiceTime] = useState('');
  const [acceptPartial, setAcceptPartial] = useState(false);
  const [serviceTimeOriginal, setServiceTimeOriginal] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const workersRef = ref(database, `workInProgress/${currentUserUid}/${orderId}/workers`);
      const serviceTimeOriginalRef = ref(database, `workInProgress/${currentUserUid}/${orderId}/serviceTimeOriginal`);
      onValue(workersRef, snapshot => setWorkers(snapshot.val()));
      onValue(serviceTimeOriginalRef, snapshot => setServiceTimeOriginal(snapshot.val()));
    };
    fetchData();
  }, [orderId, currentUserUid]);

  useEffect(() => {
    const clientRef = ref(database, `workInProgress/${currentUserUid}/${orderId}`);
    let lastKnownValue = null;

    const handleSnapshot = (snapshot) => {
      const data = snapshot.val();
      if (data?.orderStartTime) {
        setOrderStartTime(data.orderStartTime);
      }
      if (!data || !data.assignedEmployees || Object.keys(data.assignedEmployees).length === 0) {
        lastKnownValue = null;
        setAssignedEmployees({});
        return;
      }
      const newAssignedEmployees = data.assignedEmployees;
      if (JSON.stringify(newAssignedEmployees) !== JSON.stringify(lastKnownValue)) {
        setAssignedEmployees(newAssignedEmployees);
        lastKnownValue = newAssignedEmployees;
      }
    };

    onValue(clientRef, handleSnapshot);

    return () => {
      off(clientRef, 'value', handleSnapshot);
    };
  }, [orderId, currentUserUid]);

  useEffect(() => {
    if (!(orderStartTime && workers && orderId)) return;

    let timerId;

    const calculateCountdown = () => {
      const startTime = new Date(orderStartTime);
      const currentTime = new Date();
      const timeDiff = (currentTime - startTime) / 1000; // in seconds
      const remainingTime = 600 - timeDiff;

      if (remainingTime <= 0) {
        setCountdown(0);
        clearInterval(timerId);
        const confirmedEmployeesCount = Object.values(assignedEmployees).filter(emp => emp.confirmed).length;
        setConfirmedEmployeesCount(confirmedEmployeesCount);
        if (confirmedEmployeesCount && confirmedEmployeesCount > 0 && confirmedEmployeesCount < workers) {
          setShowPartialAcceptModal(true);
          setAcceptPartial(true);
        } else {
          setShowRefundModal(true);
        }
      } else {
        setCountdown(remainingTime);
      }
    };
    calculateCountdown();
    timerId = setInterval(calculateCountdown, 1000);
    return () => clearInterval(timerId);
  }, [orderStartTime, assignedEmployees, workers, orderId]);

  const formatTime = (seconds) => {
    const min = Math.floor(seconds / 60);
    const sec = Math.floor(seconds % 60);
    return `${min}:${sec < 10 ? '0' : ''}${sec}`;
  };

  const handleRefund = async (captureDriveRate = false, isFutureOrder = false) => {
    try {
      const response = await axios.post(`${config?.firebaseFunctionEndpoint}/createRefund`, {
        orderId: orderId,
        userId: currentUserUid,
        captureDriveRate,
        isFutureOrder,
      });

      if (response.data.refunded) {
        alert('Platba byla vrácena');
        navigate('/');
      } else {
        alert('Refund request failed');
      }
    } catch (error) {
      console.error('Error requesting refund:', error);
      alert('Error requesting refund.');
    }
  };

  const handleAcceptPartial = async () => {
    try {
      const response = await axios.post(`${config?.firebaseFunctionEndpoint}/acceptPartialService`, {
        currentUserUid,
        orderId,
      });
      alert('Objednávka potvrzena');
    } catch (error) {
      console.error('Error accepting partial service:', error);
    }
  };

  const handleWaitForAnotherEmployee = () => {
    setShowPartialAcceptModal(false);
  };

  const calculateUpdatedServiceTime = (serviceTimeOriginal, confirmedCount) => {
    if (!serviceTimeOriginal) {
      return;
    }
    const [hours, minutes] = serviceTimeOriginal?.split(':').map(part => part.replace(/[^\d]/g, '')).map(Number);
    const totalMinutes = hours * 60 + minutes;
    const updatedMinutes = totalMinutes / confirmedCount;
    const updatedHours = Math.floor(updatedMinutes / 60);
    const remainingMinutes = Math.round(updatedMinutes % 60);
    return `${updatedHours}h:${remainingMinutes}min`;
  };

  useEffect(() => {
    if (!serviceTimeOriginal) {
      return;
    }
    if (confirmedEmployeesCount > 0) {
      const updatedServiceTime = calculateUpdatedServiceTime(serviceTimeOriginal, confirmedEmployeesCount);
      setUpdatedServiceTime(updatedServiceTime);
    }
  }, [confirmedEmployeesCount, serviceTimeOriginal]);

  useEffect(() => {
    const clientRef = ref(database, `workInProgress/${currentUserUid}/${orderId}/page`);
    const handlePageSnapshot = (snapshot) => {
      const data = snapshot.val();
      if (data && data !== "ConfirmingPage") {
        navigate(`/${data}`, { state: { orderId: orderId } });
      }
    };

    onValue(clientRef, handlePageSnapshot);

    return () => {
      off(clientRef, 'value', handlePageSnapshot);
    };
  }, [orderId, currentUserUid, navigate]);

  if (!workers) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <img src={loadingImg} alt="loading" style={{ width: 100, height: 100 }} />
      </div>
    );
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: 'white', padding: '20px', position: 'relative', maxWidth:"100%" }}>
 

      <div style={{ marginTop: 150, display:"flex", alignItems:"center", justifyContent:"center", width:"100%" }}>
        <img src={loadingImg} alt="Loading" style={{ width: "70%", maxWidth:150 }} />
      </div>

      <div style={{ display: 'flex', flexDirection: 'row', marginTop: '20px', justifyContent: 'center', width:"100%" }}>
        {Object.keys(assignedEmployees).map(employeeId => {
          const confirmedValue = assignedEmployees[employeeId].confirmed;
          return (
            <div key={employeeId} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '0 10px' }}>
              <img src={employeePhoto} alt="Employee" style={{ width: '50px', height: '50px', borderRadius: '50%' }} />
              {confirmedValue === true ? (
                <img src={tickGreen} alt="Confirmed" style={{ width: '30px', height: '30px', marginTop: '5px' }} />
              ) : (
                <img src={loadingImg} alt="Loading" style={{ width: '30px', height: '30px', marginTop: '5px' }} />
              )}
            </div>
          );
        })}
      </div>

      <h2 style={{ color: 'black', fontWeight: 'bold', fontSize: '28px', marginTop: '20px', textAlign:"center" }}>
        Čekáme na <span style={{ color: 'lightgreen' }}>Potvrzení</span>
      </h2>
      <p style={{ textAlign: 'center', marginTop: '10px', fontSize: '12px', color: 'gray' }}>
        {workers === 1
          ? 'Našeho dodavatele jsme informovali o novém úkolu a nyní čekáme na jeho akceptaci. Prosím, vyčkejte.'
          : 'Naše dodavatele jsme informovali o novém úkolu a nyní čekáme na jejich akceptaci. Prosím, vyčkejte.'}
      </p>
      <p style={{ color: 'black', fontSize: '20px', marginTop: '20px' }}>{formatTime(countdown)}</p>

      {showRefundModal && (
        <div style={{ 
          position: 'fixed', 
          top: 0, 
          left: 0, 
          right: 0, 
          bottom: 0, 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          backgroundColor: 'rgba(0, 0, 0, 0.7)' 
        }}>
          <div style={{ 
            width: '90%', 
            maxHeight: '90%', 
            backgroundColor: '#F2F5F8', 
            borderRadius: '10px', 
            padding: '20px', 
            textAlign: 'center', 
            position: 'relative' 
          }}>
            <button 
              style={{ 
                position: 'absolute', 
                height: '25px', 
                width: '25px', 
                top: '20px', 
                left: '20px', 
                background: 'none', 
                border: 'none', 
                cursor: 'pointer' 
              }} 
              onClick={() => setShowRefundModal(false)}
            >
              <img src={xImg} alt="Close" style={{ width: '25px', height: '25px', transform: 'rotate(180deg)' }} />
            </button>
            <h2 style={{ fontWeight: 'bold', fontSize: '20px', color: 'black', marginTop: '50px' }}>Čas vypršel</h2>
            <p style={{ fontSize: '12px', color: 'black', marginTop: '10px' }}>Chcete zrušit objednávku nebo ještě počkat?</p>
            <button 
              style={{ 
                alignSelf: 'center', 
                alignItems: 'center', 
                padding: '12px', 
                marginBottom: '20px', 
                marginTop: '10px', 
                width: '100%', 
                backgroundColor: '#2ab043', 
                borderRadius: '10px', 
                color: 'white', 
                fontSize: '20px', 
                fontWeight: 'bold' 
              }} 
              onClick={() => setShowRefundModal(false)}
            >
              Počkat na potvrzení
            </button>
            <button 
              style={{ 
                alignSelf: 'center', 
                alignItems: 'center', 
                marginBottom: '20px', 
                marginTop: '20px', 
                background: 'none', 
                border: 'none', 
                cursor: 'pointer' 
              }} 
              onClick={() => { handleRefund() }}
            >
              {pressed ? (
                <img src={loadingImg} alt="Loading" style={{ width: '35px', height: '35px' }} />
              ) : (
                <img src={xImg} alt="Cancel" style={{ width: '35px', height: '35px' }} />
              )}
              <p style={{ color: 'black', fontSize: '15px' }}>Zrušit Objednávku</p>
            </button>
          </div>
        </div>
      )}

      {showPartialAcceptModal && (
        <div style={{ 
          position: 'fixed', 
          top: 0, 
          left: 0, 
          right: 0, 
          bottom: 0, 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          backgroundColor: 'rgba(0, 0, 0, 0.7)' 
        }}>
          <div style={{ 
            width: '90%', 
            maxHeight: '90%', 
            backgroundColor: '#F2F5F8', 
            borderRadius: '10px', 
            padding: '20px', 
            textAlign: 'center', 
            position: 'relative' 
          }}>
            <button 
              style={{ 
                position: 'absolute', 
                height: '25px', 
                width: '25px', 
                top: '20px', 
                left: '20px', 
                background: 'none', 
                border: 'none', 
                cursor: 'pointer' 
              }} 
              onClick={() => setShowPartialAcceptModal(false)}
            >
              <img src={xImg} alt="Close" style={{ width: '25px', height: '25px', transform: 'rotate(180deg)' }} />
            </button>
            <h2 style={{ fontWeight: 'bold', fontSize: '20px', color: 'black', marginTop: '50px' }}>
              Zatím potvrdil pouze {confirmedEmployeesCount} {confirmedEmployeesCount === 1 ? 'zaměstnanec' : 'zaměstnanci'}
            </h2>
            <p style={{ fontSize: '12px', color: 'black', marginTop: '10px' }}>
              Původní počet potřebných zaměstnanců: {workers}
            </p>
            <p style={{ fontSize: '12px', color: 'black', marginTop: '10px' }}>
              Nový odhadovaný čas služby: {updatedServiceTime}
            </p>
            <button 
              style={{ 
                alignSelf: 'center', 
                alignItems: 'center', 
                padding: '12px', 
                marginBottom: '20px', 
                marginTop: '10px', 
                width: '100%', 
                backgroundColor: '#00da00', 
                borderRadius: '10px', 
                color: 'white', 
                fontSize: '20px', 
                fontWeight: 'bold' 
              }} 
              onClick={handleAcceptPartial}
            >
              Akceptovat částečný počet
            </button>
            <button 
              style={{ 
                alignSelf: 'center', 
                alignItems: 'center', 
                padding: '12px', 
                marginBottom: '20px', 
                marginTop: '10px', 
                width: '100%', 
                backgroundColor: '#FF0000', 
                borderRadius: '10px', 
                color: 'white', 
                fontSize: '20px', 
                fontWeight: 'bold' 
              }} 
              onClick={() => handleRefund()}
            >
              Zrušit objednávku
            </button>
            <button 
              style={{ 
                alignSelf: 'center', 
                alignItems: 'center', 
                padding: '12px', 
                marginBottom: '20px', 
                marginTop: '10px', 
                width: '100%', 
                backgroundColor: '#FFA500', 
                borderRadius: '10px', 
                color: 'white', 
                fontSize: '20px', 
                fontWeight: 'bold' 
              }} 
              onClick={handleWaitForAnotherEmployee}
            >
              Čekat na potvrzení zaměstnance
            </button>
          </div>
        </div>
      )}

      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '20px' }}>
        {acceptPartial && (
          <button 
            style={{ 
              alignSelf: 'center', 
              alignItems: 'center', 
              padding: '12px', 
              marginBottom: '20px', 
              marginTop: '10px', 
              maxWidth: '50%', 
              backgroundColor: '#00da00', 
              borderRadius: '10px', 
              color: 'white', 
              fontSize: '18px', 
              fontWeight: 'bold' 
            }} 
            onClick={handleAcceptPartial}
          >
            Akceptovat částečný počet
          </button>
        )}

        <button 
          style={{ 
            alignSelf: 'center', 
            alignItems: 'center', 
            marginBottom: '20px', 
            marginTop: '20px', 
            background: 'none', 
            border: 'none', 
            cursor: 'pointer' 
          }} 
          onClick={() => handleRefund()}
        >
          {pressed ? (
            <img src={loadingImg} alt="Loading" style={{ width: '35px', height: '35px' }} />
          ) : (
            <img src={xImg} alt="Cancel" style={{ width: '35px', height: '35px' }} />
          )}
          <p style={{ color: 'black', fontSize: '15px' }}>Zrušit Objednávku</p>
        </button>
      </div>
    </div>
  );
};

export default ConfirmingPage;
