import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { getDatabase, ref, onValue, off, get } from 'firebase/database';
import employeeArrivedGif from "../images/employeeArrivedGif.gif";
import nextImg from "../images/ikonkaNext.png";
import loadingImg from '../images/loadingImage.gif';
import xImg from "../images/Xkopopup.png";
import config from '../config';
import Modal from '../components/appModal';

const EmployeeArrived = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const auth = getAuth();
  const database = getDatabase();

  const currentUserUid = auth.currentUser?.uid;
  const { orderId } = location.state;
  const [workers, setWorkers] = useState("");
  const [unreadCount, setUnreadCount] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchOrderData = async () => {
      if (currentUserUid && orderId) {
        const orderDataRef = ref(database, `workInProgress/${currentUserUid}/${orderId}`);
        const orderDataSnapshot = await get(orderDataRef);
        const orderData = orderDataSnapshot.val();
        if (orderData) {
          setWorkers(orderData.workers);
        }
      }
    };
    fetchOrderData();
  }, [currentUserUid, orderId, database]);

  useEffect(() => {
    if (currentUserUid && orderId) {
      const clientRef = ref(database, `workInProgress/${currentUserUid}/${orderId}/page`);
      const handlePageSnapshot = (snapshot) => {
        const data = snapshot.val();
        if (data && data !== "EmployeeArrived") {
          navigate(`/${data}`, { state: { orderId: orderId } });
        }
      };

      onValue(clientRef, handlePageSnapshot);

      return () => {
        off(clientRef, 'value', handlePageSnapshot);
      };
    }
  }, [orderId, currentUserUid, navigate, database]);

  useEffect(() => {
    if (currentUserUid && orderId) {
      const chatRef = ref(database, `workInProgress/${currentUserUid}/${orderId}/chat`);
      const onChatUpdate = (snapshot) => {
        const chatData = snapshot.val();
        if (chatData) {
          const unreadMessages = Object.values(chatData).filter(
            (message) => message.senderId !== currentUserUid && !message.isRead
          );
          setUnreadCount(unreadMessages.length);
        }
      };

      onValue(chatRef, onChatUpdate);

      return () => {
        off(chatRef, 'value', onChatUpdate);
      };
    }
  }, [currentUserUid, orderId, database]);

  const handleChatPress = () => {
    if (!orderId) return;
    navigate('/chat', { state: { orderId } });
  };

  if (!workers) {
    return (
      <div style={{ flex: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: "white" }}>
        <img src={loadingImg} alt="Loading" style={{ width: 100, height: 100 }} />
      </div>
    );
  }

  return (
    <div style={{ display:"flex", alignItems: "center", justifyContent:"center", flexDirection: "column", backgroundColor: "white" }}>
      <Modal isOpen={isModalOpen} onClose={()=>{setIsModalOpen(false)}} />

      <div style={{ flex: 0.1 }}></div>
      <div style={{ width: "90%", maxWidth:"300px" }}>
        <img src={employeeArrivedGif} alt="Employee Arrived" style={{ width: "100%", height: "100%" }} />
      </div>
      <p style={{ color: "black", fontSize: 12, marginTop: 0, textAlign:"center" }}>ČEKÁME U DVEŘÍ</p>
      <p style={{ color: "black", fontWeight: "bold", fontSize: 28, textAlign:"center"}}>
        {workers > 1 ? "Dodavatelé " : "Dodavatel "}
        <span style={{ color: "lightgreen", }}>{workers > 1 ? "Dorazili" : "Dorazil"}</span>
      </p>
      <p style={{ textAlign: "center", marginTop: 5, fontSize: 12, color: "black", }}>
        {workers > 1
          ? "Dodavatelé dorazili a nyní čekají u Vašich dveří. Otevřete prosím a nechte je zahájit práci."
          : "Náš dodavatel právě dorazil a nyní čeká u Vašich dveří. Otevřete prosím a nechte ho zahájit práci."}
      </p>
      <button
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: "90%",
          alignItems: "center",
          justifyContent: "center",
          marginTop: '15px',
          padding: '15px',
          backgroundColor: "#F2F5F8",
          borderRadius: '15px',
          cursor: 'pointer',
        }}
        onClick={()=>{setIsModalOpen(true)}}
      >
        <img style={{ width: '20px', height: '20px' }} src={require("../images/Chatgrey.png")} alt="Chat" />
        <p style={{ color: "gray", fontSize: '13px', textAlign: "center", marginLeft: '10px', fontWeight: "bold", textDecoration: "underline" }}>
          {`Otevřít chat s dodavatelem (${unreadCount})`}
        </p>
      </button>
    </div>
  );
};

export default EmployeeArrived;
